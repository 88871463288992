import './styles/overview.css';
import React, { useMemo, useState } from 'react';

import FAQ from './faq.js';
import ContactUs from './contactUs.js';
import Tickets from './tickets.js';

function Overview({ setSelection }) {
  const [header, setHeader] = useState(0);

  useMemo(() => {
    setTimeout(() => {
      const faqContainer = document.getElementById('overview-faq-container');
      const faqTitle = document.getElementById('overview-faq-title');

      const ticketContainer = document.getElementById(
        'overview-ticket-container'
      );
      const ticketTitle = document.getElementById('overview-ticket-title');

      const contactContainer = document.getElementById(
        'overview-contact-us-container'
      );
      const contactTitle = document.getElementById('overview-contact-us-title');

      if (header === 0) {
        faqContainer.style.backgroundColor = '#6950e81a';
        faqTitle.style.color = '#2e8bff';
        ticketContainer.style.backgroundColor = '#f6f6f6';
        ticketTitle.style.color = '#6b7280';
        contactContainer.style.backgroundColor = '#f6f6f6';
        contactTitle.style.color = '#6b7280';
      } else if (header === 1) {
        faqContainer.style.backgroundColor = '#f6f6f6';
        faqTitle.style.color = '#6b7280';
        ticketContainer.style.backgroundColor = '#6950e81a';
        ticketTitle.style.color = '#2e8bff';
        contactContainer.style.backgroundColor = '#f6f6f6';
        contactTitle.style.color = '#6b7280';
      } else {
        faqContainer.style.backgroundColor = '#f6f6f6';
        faqTitle.style.color = '#6b7280';
        ticketContainer.style.backgroundColor = '#f6f6f6';
        ticketTitle.style.color = '#6b7280';
        contactContainer.style.backgroundColor = '#6950e81a';
        contactTitle.style.color = '#2e8bff';
      }
    }, 100);
  }, [header]);

  return (
    <div id="overview-container">
      <h1 id="overview-header">How can we help you?</h1>
      <div id="overview-topics-container">
        <section>
          <div
            id="overview-faq-container"
            className="overview-titles-containers"
          >
            <p
              id="overview-faq-title"
              className="overview-titles"
              onClick={() => {
                setSelection(<FAQ />);
                setHeader(0);
              }}
            >
              FAQs
            </p>
          </div>
          <div
            id="overview-ticket-container"
            className="overview-titles-containers"
          >
            <p
              id="overview-ticket-title"
              className="overview-titles"
              onClick={() => {
                setSelection(<Tickets />);
                setHeader(1);
              }}
            >
              Tickets
            </p>
          </div>
          <div
            id="overview-contact-us-container"
            className="overview-titles-containers"
          >
            <p
              id="overview-contact-us-title"
              className="overview-titles"
              onClick={() => {
                setSelection(<ContactUs />);
                setHeader(2);
              }}
            >
              Contact Us
            </p>
          </div>
        </section>
        <section>
          <button id="overview-create-ticket-button">Create Ticket</button>
        </section>
      </div>
    </div>
  );
}

export default Overview;
