import './styles/login.css';
import React, { useEffect, useState } from 'react';
import Login from '../../components/Login/login.js';
import Description from '../../components/Login/description.js';
import cimitLogo from '../../images/citmitLogos/Citmit.svg';

function LoginPage({ setToken, setRememberToken }) {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    // check to see if the user is using a mobile device
    // yes the regex checks all that, no I didn't write it
    function checkMobile() {
      let w = window.innerWidth;
      let h = window.innerHeight;

      if (w < 500 || h < 600) {
        setMobile(true);
      }
    }

    checkMobile();
  }, []);

  function IsMobile() {
    return (
      <div id="mobile-container">
        <img src={cimitLogo} alt="citmit logo" id="citmit-logo-black" />
        <h1 id="mobile-header">Buildpermit.ai is best used on desktop</h1>
      </div>
    );
  }

  function IsNotMobile() {
    return (
      <div id="login-container">
        <section id="description">
          <Description />
        </section>
        <section id="sign-in">
          <Login setToken={setToken} setRememberToken={setRememberToken} />
        </section>
      </div>
    );
  }

  return (
    <div id="mobile-check-container">
      {mobile ? <IsMobile /> : <IsNotMobile />}
    </div>
  );
}

export default LoginPage;
