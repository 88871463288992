/*
 * May 7, Raymond: finished this page and styled. have not tested
 * this page should only be accessible once they confirm their email and
 * the email should give them a unique url so that a session could be started
 * with their information
 */
import './styles/accountCreated.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AccountConfirm from '../../components/Emailonly/accountCreated.js';
import VerifyAccount from '../../components/Emailonly/verifyAccount.js';
import Loading from '../../components/Loading/loading.js';

function AccountCreated() {
  const [verified, setVerified] = useState(false);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    async function checkVerification() {
      axios
        .get('https://buildpermit.ai:4000/confirmation/checkVerify', {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log('account verified');
            setVerified(true);
          } else {
            console.log('account not verified');
            setVerified(false);
          }
        })
        .catch((error) => {
          console.error('error when connecting to backend', error);
          setVerified(false);
        })
        .finally(() => {
          setChecking(false);
        });
    }

    checkVerification();
  }, [setVerified]);

  return (
    <div id="account-created-container">
      {checking ? (
        <Loading subject={'checking if logged in account is verified...'} />
      ) : verified ? (
        <AccountConfirm />
      ) : (
        <VerifyAccount setVerified={setVerified} />
      )}
    </div>
  );
}

export default AccountCreated;
