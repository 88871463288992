// Author: Andrew Park
// Contact: Andrew.P

// TODO: Indicate that the AI is thinking (secondary)
import React, { useState } from 'react';
import './styles/planCheckPrompt.css';
import { useRef, useEffect } from 'react';
import axios from 'axios';

const PlanCheckPrompt = (selectedPdf) => {
  const [pdf, setpdf] = useState(selectedPdf);
  const bottomOfPanelRef = useRef(null);
  const divRef = useRef(null);
  const sendDivRef = useRef(null);

  /*
   * temporarly disabling this, will update later
   *
  const prompts = [
    'What is APN?',
    'What is Squarefootage?',
    'Where can I find FAR?',
    'What are the setbacks?',
    'What is the occupancy type?',
  ];
  */

  const prompts = [];

  const [customPrompt, setCustomPrompt] = useState(''); // these prompts are custom prompts that the user can add to their message box
  const [pastPrompts, setPastPrompts] = useState([]); // these prompts are the prompts that the user has added to their message box
  const [pastDialogs, setPastDialogs] = useState([]); // these are the past dialogs that the user has had with the chatbot

  const [messageY, setMessageY] = useState(0);
  const [sendY, setSendY] = useState(0);

  // Function that sends the custom prompt to the chatbot
  const sendMessage = async (event) => {
    setpdf(selectedPdf);
    event.preventDefault();
    if (customPrompt === '') {
      return;
    }
    let message = document.getElementById('custom-prompt-input').value;
    document.getElementById('custom-prompt-input').value = '';
    const id = sessionStorage.getItem('file_id');
    const name = sessionStorage.getItem('file_name');
    const payload = {
      id: id,
      name: name,
      message: message,
      pdf: pdf,
    };

    // contact backend and get an ai response. had to adjust to both catch the response
    // when it is able to respond (sometimes takes a few seconds) and also when its unable
    // to connect
    let aiResponse = await axios
      .post('https://buildpermit.ai:4000/prompt', payload)
      .then((response) => {
        console.log('message from backend', response.data.response);
        let tempResponse = response.data.response;
        setPastDialogs([
          ...pastDialogs,
          { user: customPrompt, bot: tempResponse },
        ]);
        setPastPrompts([...pastPrompts, customPrompt]);
        setCustomPrompt('');
      })
      .catch((error) => {
        console.log('unable to contact the backend');
        console.log('error:', error);
        let tempResponse = 'sorry, an error occured while using this software';
        setPastDialogs([
          ...pastDialogs,
          { user: customPrompt, bot: tempResponse },
        ]);
        setPastPrompts([...pastPrompts, customPrompt]);
        setCustomPrompt('');
      });
  };

  // Function that sends the recommended question to the chatbot
  const sendMessageRecQuestion = async (event) => {
    event.preventDefault();
    let index = event.target.id;
    const id = sessionStorage.getItem('file_id');
    const name = sessionStorage.getItem('file_name');
    const payload = {
      id: id,
      name: name,
      message: index,
      pdf: pdf,
    };
    let aiResponse = await axios
      .post('https://buildpermit.ai:4000/prompt', payload)
      .then((response) => {
        console.log('message from backend', response.data.response);
        let tempResponse = response.data.response;
        setPastDialogs([
          ...pastDialogs,
          { user: prompts[index], bot: tempResponse },
        ]);
        setPastPrompts([...pastPrompts, prompts[index]]);
      })
      .catch((error) => {
        console.log('unable to contact the backend');
        console.log('error:', error);
        let tempResponse = 'sorry, an error occured while using this software';
        setPastDialogs([
          ...pastDialogs,
          { user: prompts[index], bot: tempResponse },
        ]);
        setPastPrompts([...pastPrompts, prompts[index]]);
      });
  };

  // UseEffect that scrolls to the bottom of the message box
  useEffect(() => {
    if (bottomOfPanelRef.current) {
      bottomOfPanelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [pastDialogs]);

  useEffect(() => {
    if (bottomOfPanelRef.current) {
      bottomOfPanelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [pastPrompts]);

  useEffect(() => {
    const measureDivPosition = () => {
      if (divRef.current) {
        setMessageY(divRef.current.getBoundingClientRect().top);
      }
    };

    measureDivPosition();

    window.addEventListener('scroll', measureDivPosition);
    window.addEventListener('resize', measureDivPosition);

    return () => {
      window.removeEventListener('scroll', measureDivPosition);
      window.removeEventListener('resize', measureDivPosition);
    };
  });

  useEffect(() => {
    const measureSendPosition = () => {
      if (sendDivRef.current) {
        setSendY(sendDivRef.current.getBoundingClientRect().top);
      }
    };

    measureSendPosition();

    window.addEventListener('scroll', measureSendPosition);
    window.addEventListener('resize', measureSendPosition);

    return () => {
      window.removeEventListener('scroll', measureSendPosition);
      window.removeEventListener('resize', measureSendPosition);
    };
  });

  return (
    <div style={{ height: '95vh', width: '20vw' }}>
      <div className="outside-box">
        <p className="plan-check-prompt-title">Plan Check Prompt</p>
        {prompts.map((prompt, index) => (
          <button
            className="recommended-question-button"
            onClick={sendMessageRecQuestion}
            id={index}
          >
            {prompt}
          </button>
        ))}
        <div
          ref={divRef}
          className="message-box-area"
          style={{ height: `calc(${sendY}px - ${messageY}px)` }}
        >
          {pastPrompts.map((pastPrompt, index) => (
            <div>
              <div className="message-box-user">
                <p className="message-user">{pastPrompt}</p>
              </div>
              <div className="message-box-response">
                <p className="message-response">{pastDialogs[index].bot}</p>
              </div>
              <div ref={bottomOfPanelRef}></div>
            </div>
          ))}
        </div>
        <div ref={sendDivRef} className="custom-prompt">
          <input
            type="text"
            className="custom-prompt-input"
            placeholder="Suggest Comment"
            id="custom-prompt-input"
            onChange={(event) => setCustomPrompt(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                sendMessage(event);
              }
            }}
          ></input>
          <button
            className="custom-prompt-button"
            onClick={sendMessage}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default PlanCheckPrompt;
