/* Andrew Park June 21, 2024
 * This component is used to display the billing page.
 */

import React from "react";
import Navbar from "../../components/Navbar/navbar.js";
import "./styles/invoice.css";
import BillingTile from "../../components/Billing/billingTile.js";
import TopNav from "../../components/Navbar/topnav.js";

function Billing() {
  return (
    <div id="invoice-container">
      <section id="main-navbar-invoice">
        <Navbar currentPage={"invoice"} />
      </section>
      <section id="top-nav-invoice">
        <TopNav />
      </section>
      <section id="invoice-tiles-container">
        <BillingTile id="invoice-tile-invoice" />
      </section>
    </div>
  );
}

export default Billing;
