import React from "react";

import "./styles/userList.css";
import Navbar from "../../components/Navbar/navbar";
import TopNav from "../../components/Navbar/topnav";
import UserListTile from "../../components/Users/UserListTile";

function UserList() {
  return (
    <div id="user-list-container">
      <section id="main-navbar-user-list">
        <Navbar currentPage={"users"} />
      </section>
      <section id="top-nav-user-list">
        <TopNav />
      </section>
      <section id="user-list-tiles-container">
        <UserListTile />
      </section>
    </div>
  );
}

export default UserList;
