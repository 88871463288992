import React, { useState } from 'react';
import PlanCheckPrompt from '../../components/Plancheck/planCheckPrompt.js';
import FileDropzone from '../../components/Plancheck/dropzone.js';
import Navbar from '../../components/Navbar/navbar.js';
import './styles/plancheck.css';

const PlanCheck = () => {
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handlePdfSelect = (pdfFile) => {
    setSelectedPdf(pdfFile);
  };

  return (
    <div className="plancheckwindow">
      <section id="navbar">
        <Navbar />
      </section>
      <h1 id="header">
        <span id="companyName">BuildPermit</span>
        <span id="restOfTitle">.ai</span>
      </h1>
      <section id="plancheckdrop">
        <FileDropzone
          onPdfSelect={handlePdfSelect}
          onChange={handlePdfSelect}
        />
      </section>
      <section id="prompt">
        <PlanCheckPrompt selectedPdf={selectedPdf} />
      </section>
    </div>
  );
};

export default PlanCheck;
