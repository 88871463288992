import { useState } from 'react';

export default function useRemember() {
  const getToken = () => {
    try {
      const tokenString = localStorage.getItem('rememberToken');
      const userToken = JSON.parse(tokenString);
      return userToken;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [rememberToken, setRememberToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem('rememberToken', JSON.stringify(userToken));
    setRememberToken(userToken.token);
  };

  return {
    setToken: saveToken,
    rememberToken,
  };
}
