/* Andrew Park June 21, 2024
 * This component is used to display the invoice list.
 */

import React from "react";
import EachBill from "./EachBill";
import "./styles/billingTile.css";

import billingImage from "../../images/billing_icon.svg";

function BillingTile() {
  return (
    <div id="invoice-tile">
      <section id="invoice-title-container">
        <section id="invoice-tile-top-section">
          <img src={billingImage} alt="billing" id="billing-logo" />
          <h2 id="invoice-tile-title">Billing</h2>
        </section>
        <section id="invoice-tags">
          <p className="invoice-each-tag">Project</p>
          <p className="invoice-each-tag">ID</p>
          <p className="invoice-each-tag">Review Cost</p>
          <p className="invoice-each-tag">Date</p>
          <p className="invoice-each-tag">Receipt</p>
        </section>
        <div className="each-invoice-group-container">
          <EachBill />
        </div>
      </section>
    </div>
  );
}

export default BillingTile;
