import './styles/success.css';
import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import blueCheck from '../../images/blue-checkmark.svg';

function Success() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    function paymentSuccess() {
      const sessionId = searchParams.get('session_id');
      const projectId = searchParams.get('project_id');
      const cookie = searchParams.get('cookie');

      console.log('this is sessionId', sessionId);
      console.log('this is projectid', projectId);
      console.log('this is cookie', cookie);

      axios
        .get(
          `https://buildpermit.ai:4000/payment/success?session_id=${sessionId}&project_id=${projectId}&cookie=${cookie}`,
          { withCredentials: true }
        )
        .then((res) => {
          console.log('this is res payment success', res);
        })
        .catch((error) => {
          console.log('an error occured on payment success');
          console.error(error);
        });
    }

    paymentSuccess();
  }, [searchParams]);

  function goto(e) {
    e.preventDefault();
    navigate('/projects');
  }

  return (
    <div id="success-page-container">
      <img src={blueCheck} alt="blue checkmark" id="success-blue-checkmark" />
      <h1 id="payment-success-header">Payment Successful!</h1>
      <p id="payment-success-description">Your payment has been completed</p>
      <button id="payment-success-button" onClick={goto}>
        Finish
      </button>
    </div>
  );
}

export default Success;
