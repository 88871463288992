import { useState } from 'react';
import citmitLogo from '../../images/citmitLogos/CITMIT WHITE [ENLARGED].png';
import './styles/description.css';

function Description() {
  // TODO (future functionality) add functionality for rotating through descriptions of what this web app
  // can do in descript section. This hook is for that
  const [description, setDescription] = useState('');

  return (
    <div id="description-container">
      <section id="title">
        <div id="logo-container">
          <img src={citmitLogo} alt="citmit logo" id="logo" />
        </div>
      </section>
      <section id="descript">
        <p id="first-descript">Automate the Build Permit process</p>
        <p id="second-descript">
          Citmit utilizes advanced AI to conduct plan checks
        </p>
      </section>
    </div>
  );
}

export default Description;
