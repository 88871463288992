import React, { useEffect, useState } from 'react';
import CreateNewFolderSharpIcon from '@mui/icons-material/CreateNewFolderSharp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './styles/project.css';
import './styles/createProject.css';

import Navbar from '../../components/Navbar/navbar.js';
import Topnav from '../../components/Navbar/topnav.js';
import ProjectDetailTile from '../../components/Project/CreateProject/projectDetailTile.js';
import AdditionalDetailsTile from '../../components/Project/CreateProject/additionalDetailsTile.js';
import FileUploadTile from '../../components/Project/CreateProject/fileUploadTile.js';
import SupplementalFileUpload from '../../components/Project/CreateProject/supplementalFileUpload.js';
import FinishedPopup from '../../components/PopupWindow/finishedProject.js';
import DraftPopup from '../../components/PopupWindow/draftPopup.js';

function CreateProject() {
  const [projectName, setProjectName] = useState(null);
  const [cost, setCost] = useState(null);
  const [sprinklered, setSprinklered] = useState(null);
  const [height, setHeight] = useState(null);
  const [existingFeet, setExistingFeet] = useState(null);
  const [proposedFeet, setProposedFeet] = useState(null);
  const [location, setLocation] = useState(null);
  const [parcel, setParcel] = useState(null);
  const [acres, setAcres] = useState(null);
  const [zoning, setZoning] = useState(null);
  const [occupancy, setOccupancy] = useState(null);
  const [client, setClient] = useState(null);
  const [permitType, setPermitType] = useState(null);
  const [description, setDescription] = useState(null);
  const [constDocs, setConstDocs] = useState(null);
  const [suppDocs, setSuppDocs] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [draftLoad, setDraftLoad] = useState(true);
  const [draftDescription, setDraftDescription] = useState();
  const [projectCount, setProjectCount] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      projectName &&
      cost &&
      sprinklered !== null &&
      height &&
      existingFeet &&
      proposedFeet &&
      location &&
      parcel &&
      acres &&
      zoning &&
      occupancy &&
      client &&
      permitType &&
      description &&
      (constDocs || suppDocs)
    ) {
      setIsDraft(true);
    }
  }, [
    projectName,
    cost,
    sprinklered,
    height,
    existingFeet,
    proposedFeet,
    location,
    parcel,
    acres,
    zoning,
    occupancy,
    client,
    permitType,
    description,
    constDocs,
    suppDocs,
  ]);

  function createProject(e, draft) {
    e.preventDefault();
    showDraftPopup();
    setDraftDescription('Saving project details...');

    if (!projectName) {
      alert('must set a project name');
      document.getElementById('project-name-field').style.border =
        '1.5px solid red';
      return;
    }

    if (
      !draft &&
      (!cost ||
        sprinklered === null ||
        !height ||
        !existingFeet ||
        !proposedFeet ||
        !location ||
        !parcel ||
        !acres ||
        !zoning ||
        !occupancy ||
        !client ||
        !permitType ||
        !description ||
        (!constDocs && !suppDocs))
    ) {
      alert(
        'cannot save project if you have missing information, but you can save as a draft'
      );
      document.getElementById('save-draft-project-button').style.border =
        '1px solid red';
      return;
    }

    // regex to get rid of commas in numbers
    let noCommaCost;
    let noCommaExisting;
    let noCommaProposed;
    let noCommaParcel;
    let noCommaAcres;

    if (cost) noCommaCost = parseInt(cost.replace(/,/g, ''), 10);
    if (existingFeet)
      noCommaExisting = parseInt(existingFeet.replace(/,/g, ''), 10);
    if (proposedFeet)
      noCommaProposed = parseInt(proposedFeet.replace(/,/g, ''), 10);
    if (parcel) noCommaParcel = parseInt(parcel.replace(/,/g, ''), 10);
    if (acres) noCommaAcres = parseInt(acres.replace(/,/g, ''), 10);

    const data = {
      projectName: projectName,
      costEstimate: noCommaCost,
      isSprinklered: sprinklered,
      buildingHeight: height,
      existingSquareFeet: noCommaExisting,
      proposedSquareFeet: noCommaProposed,
      location: location,
      parcelNumber: noCommaParcel,
      acres: noCommaAcres,
      zoning: zoning,
      occupancyType: occupancy,
      permitType: permitType,
      client: client,
      description: description,
    };

    axios
      .post('https://buildpermit.ai:4000/projects', data, {
        withCredentials: true,
      })
      .then(async (response) => {
        if (response.status === 201) {
          setProjectCount(response.data.response.project_count_id);
          setDraftDescription('Saving documents...');
          const uploaded = await uploadDocuments(
            response.data.response.project_count_id,
            response.data.response.project_name
          );
          if (uploaded && !draft) {
            closeDraftPopup();
            showPaymentPopup();
            /*
            navigate('/projects', {
              state: { createdProject: true },
            });
            */
          } else {
            setDraftLoad(false);
          }
        } else {
          alert(
            'an error has occured on our servers, we are unable to create your new project'
          );
          console.log('hit the backend but sent back an error code');
          console.log('status', response.status);
          console.log('data', response.data);
        }
      })
      .catch((error) => {
        alert('unable to connect to citmit servers');
        console.log('unable to communicate with the backend');
        console.error(error);
      });
  }

  async function uploadDocuments(projectId, projectName) {
    if (!constDocs || !suppDocs) {
      return false;
    }

    // try this way first, if it doesnt work then try the other way
    const formData = new FormData();
    formData.append('constructionDocuments', constDocs);
    formData.append('supplementalDocuments', suppDocs);
    formData.append('projectCountId', projectId);
    formData.append('projectName', projectName);

    return await axios
      .post('https://buildpermit.ai:4000/docs', formData, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 201) {
          console.log('this is response after docuemnts are uploaded', res);
          return true;
        } else {
          console.log('unable to upload docs', res);
          return false;
        }
      })
      .catch((error) => {
        console.error('hit an error when uploading docs', error);
        return false;
      });
  }

  // used to display popup and disable/blur background
  function showPaymentPopup() {
    document.getElementById('display-finished-project-popup').style.display =
      'block';
    const background = document.getElementById('blur-background');
    background.style.position = 'fixed';
    background.style.top = '0';
    background.style.right = '0';
    background.style.bottom = '0';
    background.style.left = '0';
    background.style.zIndex = '1000';
    background.style.backdropFilter = 'blur(2px)';
  }

  // used to display draft popup and disable/blur background
  function showDraftPopup() {
    document.getElementById('display-draft-project-popup').style.display =
      'block';
    const background = document.getElementById('blur-background');
    background.style.position = 'fixed';
    background.style.top = '0';
    background.style.right = '0';
    background.style.bottom = '0';
    background.style.left = '0';
    background.style.zIndex = '1000';
    background.style.backdropFilter = 'blur(2px)';
  }

  function closeDraftPopup() {
    document.getElementById('display-draft-project-popup').style.display =
      'none';
  }

  return (
    <div id="project-container">
      <section id="main-navbar-projects">
        <Navbar />
      </section>
      <section id="top-navbar-create-project">
        <Topnav />
      </section>
      <section id="create-projects-tiles-container">
        <div id="blur-background" />
        <div id="display-finished-project-popup">
          <FinishedPopup cost={cost} projectId={projectCount} />
        </div>
        <div id="display-draft-project-popup">
          <DraftPopup
            draftLoad={draftLoad}
            draftDescription={draftDescription}
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', margin: '0 1rem' }}
          id="create-project-header"
        >
          <CreateNewFolderSharpIcon id="create-project-icon" />
          <h1 id="create-project-page-title">Create New Project</h1>
          <a href="/projects" id="create-project-title-link">
            &lt; back to projects
          </a>
        </div>
        <section style={{ display: 'flex', flexDirection: 'row' }}>
          <ProjectDetailTile
            projectName={setProjectName}
            cost={setCost}
            sprinklered={setSprinklered}
            height={setHeight}
            existingFeet={setExistingFeet}
            proposedFeet={setProposedFeet}
            location={setLocation}
            id="projects-tile-projects"
          />
          <AdditionalDetailsTile
            parcel={setParcel}
            acres={setAcres}
            zoning={setZoning}
            occupancy={setOccupancy}
            client={setClient}
            permitType={setPermitType}
            description={setDescription}
            id="projects-tile-projects"
          />
        </section>
        <section style={{ display: 'flex', flexDirection: 'row' }}>
          <FileUploadTile
            constDocs={setConstDocs}
            id="projects-tile-projects"
          />
          <SupplementalFileUpload
            suppDocs={setSuppDocs}
            id="projects-tile-projects"
          />
        </section>
        <section id="create-project-button-container">
          {isDraft ? (
            <button
              id="create-project-button"
              onClick={(event) => createProject(event, false)}
            >
              Submit Project
            </button>
          ) : (
            <button
              id="save-draft-project-button"
              onClick={(event) => createProject(event, true)}
            >
              &#43; Save Draft
            </button>
          )}
        </section>
      </section>
    </div>
  );
}

export default CreateProject;
