import React from "react";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

import PDFViewerPopUp from "./PDFViewerPopUp";

import "./styles/eachbill.css";

function EachBill() {
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  // dummy data for the billing -> delete if not needed
  const dummyData = [
    {
      project: "Project 1",
      id: "1",
      review_cost: "100",
      date: "2021-05-09",
      status: "paid",
      timestamp: 1620583200000,
      receipt: "receipt",
    },
    {
      project: "Project 2",
      id: "2",
      review_cost: "200",
      date: "2021-06-10",
      status: "unpaid",
      timestamp: 1670583200000,
      receipt: "receipt",
    },
    {
      project: "Project 3",
      id: "3",
      review_cost: "500",
      date: "2021-07-11",
      status: "paid",
      timestamp: 1620980200000,
      receipt: "receipt",
    },
  ];

  // format timestamp to readable date
  // the input is Unix epoch time in milliseconds
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // handles the click function for the receipt button
  const handleReceipt = () => {
    setShowPDFViewer(!showPDFViewer);
  };

  const handleDownload = (event) => {
    // download the receipt
    console.log("Download receipt");
  };

  return (
    <>
      {dummyData.map((invoice) => (
        <li id="each-invoice-container">
          <span id="each-invoice-header">{invoice.project}</span>
          <span id="each-invoice-id">{invoice.id}</span>
          <span id="each-invoice-review-cost">$ {invoice.review_cost}</span>
          <span id="each-invoice-time">
            {formatTimestamp(invoice.timestamp)}
          </span>
          <span id="each-invoice-receipt">
            <div className="invoice-receipt-button-container">
              <div className="invoice-receipt-button-inner-container">
                <button
                  className="invoice-receipt-button"
                  onClick={handleReceipt}
                >
                  View Receipt
                </button>
                <PDFViewerPopUp
                  show={showPDFViewer}
                  onClose={handleReceipt}
                  pdfURL="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                />
                <div className="invoice-download-button">
                  <DownloadIcon
                    className="invoice-receipt-download-icon"
                    onClick={handleDownload}
                  />
                </div>
              </div>
            </div>
          </span>
        </li>
      ))}
    </>
  );
}

export default EachBill;
