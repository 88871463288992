// Author: Andrew Park
// Contact: Andrew.P

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './styles/logo.css';
import smalllogo from '../../images/small-logo.png';

const Logo = () => {
  return (
    <div class="logo-div">
      <NavLink to="/">
        <img src={smalllogo} alt="largelogo" class="logo" />
      </NavLink>
    </div>
  );
};

export default Logo;
