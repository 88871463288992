import React from 'react';
import axios from 'axios';
import building from '../../images/samples/building.png';

function ProjectImage(props) {
  return (
    <div className="project-image">
      <img src={building} alt="Project Render" />
    </div>
  );
}

export default ProjectImage();
