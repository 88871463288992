/* Author: Aaron Kann  
   Email: aaron@stevek.com*/
import './styles/dashboard.css';
import React from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// importing images for demo, can delete later once we figure out how to
// show images for general cases
import plus from '../../images/Plus Icon.png';
import Navbar from '../../components/Navbar/navbar.js';
import Project from '../../components/Dashboard/project.js';
import projectImage from '../../components/Dashboard/projectImage.js';

function Dashboard() {
  const [projects, setProjects] = React.useState([]);
  const [authenticated, setAuthenticated] = React.useState();
  const [id, setId] = React.useState();
  const cookies = new Cookies();

  function getProjects() {
    axios
      .get('https://buildpermit.ai:4000/dashboard', {
        crossdomain: true,
        params: { id: id },
      })
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  React.useEffect(() => {
    const loggedInUser = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('id');
    console.log('this is cookies in dashboard', cookies.get('token2'));
    console.log('this is loggedInUser', loggedInUser);
    if (loggedInUser) {
      setAuthenticated(true);
    }
    if (userId) {
      setId(userId);
    }
  }, [setAuthenticated, setId]);

  //src="../images/icons/plus.png"

  return (
    <div id="dashboard">
      <section id="navbar">
        <Navbar />
      </section>
      <section id="header">
        <h1>Dashboard</h1>
      </section>
      <section id="searchcreate">
        <button id="createproject" name="createproject">
          Create
        </button>
        <input type="text" id="search-bar" name="search-bar" />
        <button id="searchbutton" name="search-bar">
          Search
        </button>
      </section>
      <section className="projects-list">
        {projects.map((project) => (
          <Project
            percentage={project.percentage}
            name={project.name}
            address={project.address}
            image={project.image}
          />
        ))}
      </section>
    </div>
  );
}

export default Dashboard;
