/*
 * May 7, Raymond: finished page and component along with styling. details may need
 * to be changed. still need to test with backend
 * TODO also only need to be accessible via email link and have it so that the email
 * sends us session info so we know who is resetting their password
 */
import './styles/resetPassword.css';
import React from 'react';
import PasswordReset from '../../components/Emailonly/resetPassword.js';

function ResetPassword() {
  return (
    <section id="reset-password-container">
      <PasswordReset />
    </section>
  );
}

export default ResetPassword;
