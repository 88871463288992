/*
 * May 11, Raymond: making individual components for
 * status implementation
 *
 * TODO: finish building and test
 */
import './styles/status.css';
import React from 'react';
import blueDownload from '../../../images/blue-download.svg';

function Checked() {
  // need id of the project and get project document, both original and corrected pdf's
  // are held in this. Grab corrected with this pdf and set as a downloadable file
  function downloadPDF() {}

  return (
    <div id="checked-status-container">
      <img src={blueDownload} alt="download pdf" id="blue-download-status" />
      <div id="checked-status-inner-container">
        <div id="checked-div">Checked</div>
      </div>
    </div>
  );
}

function Pending() {
  return (
    <div id="pending-status-container">
      <div id="pending-status-inner-container">
        <div id="pending-div">Pending</div>
      </div>
    </div>
  );
}

function OnHold() {
  return (
    <div id="onhold-status-container">
      <div id="onhold-status-inner-container">
        <div id="onhold-div">On Hold</div>
      </div>
    </div>
  );
}

function InProgress() {
  return (
    <div id="in-progress-status-container">
      <div id="in-progress-inner-container">
        <div id="in-progress-div">In Progress</div>
      </div>
    </div>
  );
}

function Ready({ readyToPay, projectId, cost }) {
  return (
    <div id="ready-status-container">
      <div id="ready-inner-container">
        <div
          id="ready-div"
          onClick={(event) => {
            readyToPay(event, projectId, cost);
          }}
        >
          Ready to Checkout!
        </div>
      </div>
    </div>
  );
}

export { Checked, Pending, OnHold, InProgress, Ready };
