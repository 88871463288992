/* Author: Andrew Park
 Contact: Andrew.P */

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Login pages
import LoginPage from './pages/Login/login.js';
import Signup from './pages/Login/signup.js';
import AccountRecovery from './pages/Login/forgotPassword.js';
import ChangePassword from './pages/Login/changePassword.js';

//TODO make these next two only accessible via email links
import AccountCreated from './pages/Emailonly/accountCreated.js';
import ResetPassword from './pages/Emailonly/resetPassword.js';

//project pages
import Project from './pages/Project/project.js';
import CreateProject from './pages/Project/createProject.js';
import ExpandedProjects from './pages/Project/expandedProject.js';
import Doc from './pages/Plancheck/documents.js';
import EditProject from './pages/Project/editProject.js';

//payment pages
import Success from './pages/Payments/success.js';
import Failure from './pages/Payments/failure.js';

//support pages
import SupportPage from './pages/Support/support.js';

import PlanCheck from './pages/Plancheck/plancheck.js';
import Profile from './pages/Profile/profile.js';

import Dashboard from './pages/Dashboard/dashboard.js';
import Inbox from './pages/Inbox/inbox.js';
import UserPage from './pages/Users/UserPage.js';
import Billing from './pages/Billing/billing.js';
import UserList from './pages/Users/UserList.js';

import useToken from './components/Auth/useToken.js';
import useRemember from './components/Auth/setRemember.js';

function App() {
  const { token, setToken } = useToken();
  const { rememberToken, setRememberToken } = useRemember();

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LoginPage
                setToken={setToken}
                setRememberToken={setRememberToken}
              />
            }
          />
          <Route path="/account-recovery" element={<AccountRecovery />} />
          <Route
            path="/account-recovery/change-password"
            element={<ChangePassword />}
          />
          <Route path="/account-verification" element={<AccountCreated />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/plancheck" element={<PlanCheck />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/projects/create" element={<CreateProject />} />
          <Route
            path="/projects/expanded-project"
            element={<ExpandedProjects />}
          />
          <Route path="/projects/document" element={<Doc />} />
          <Route path="/projects/edit" element={<EditProject />} />
          <Route path="/users/profile" element={<UserPage />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/help" element={<SupportPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
