/**
 * May 15 Andrew: Only accepts pdf files
 */

import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import IconButton from "@mui/material/IconButton";

import "./styles/fileUploadTile.css";

function FileUploadTile({ constDocsSetter, existingFiles = [] }) {
  if (existingFiles.length > 0) {
    constDocsSetter(existingFiles);
  }
  const [files, setFiles] = useState([]);
  console.log("files", existingFiles);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      const file = acceptedFiles[0];
      constDocsSetter(file);
    },
    [constDocsSetter]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    style: {
      display: "none",
    },
    noClick: files.length > 0,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleDelete = (file) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
  };

  const renderFiles = () => {
    return files.map((file) => {
      return (
        <li id="file-list-containter">
          <p id="file-name-uploader">{file.name}</p>
          <IconButton
            id="delete-icon-button"
            size="small"
            onClick={() => handleDelete(file)}
          >
            <DeleteIcon id="delete-button-file" />
          </IconButton>
        </li>
      );
    });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  return (
    <div id="file-upload-tile-container">
      <div id="file-uploader" {...getRootProps()}>
        <input
          type="file"
          style={{
            display: "none",
          }}
          onChange={handleFileChange}
          multiple
          {...getInputProps()}
        />

        {files.length === 0 ? (
          <>
            <UploadFileRoundedIcon id="upload-file-icon" />
            <p>Drop your Construction Documents here</p>
            <p id="file-upload-button">Click to Browse</p>
          </>
        ) : (
          <>
            <div id="render-file-container">{renderFiles()}</div>
            <button id="file-add-button" onClick={open}>
              Add Files
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default FileUploadTile;
