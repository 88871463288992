import React from "react";

import "./styles/eachUserList.css";

import editPencil from "../../images/edit_icon.svg";
import deleteCan from "../../images/icons/delete_can.svg";

function EachUserList({ name, username, email, role }) {
  return (
    <li id="each-user-container">
      <span id="each-user-name">{name}</span>
      <span id="each-user-username">{username}</span>
      <span id="each-user-email">{email}</span>
      <span id="each-user-role">{role}</span>
      <span id="each-user-edit-buttons">
        <img
          src={editPencil}
          alt="edit pencil"
          className="edit-button-clickable"
        />
        <img
          src={deleteCan}
          alt="delete can"
          className="edit-button-clickable"
        />
      </span>
    </li>
  );
}

export default EachUserList;
