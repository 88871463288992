import './styles/support.css';
import React, { useMemo, useState } from 'react';
import axios from 'axios';

import Navbar from '../../components/Navbar/navbar.js';
import TopNav from '../../components/Navbar/topnav.js';
import Overview from '../../components/Support/overview.js';
import FAQ from '../../components/Support/faq.js';

function SupportPage() {
  const [selection, setSelection] = useState();

  useMemo(() => {
    setSelection(<FAQ />);
  }, []);

  return (
    <div id="support-page-container">
      <section id="main-navbar-support">
        <Navbar />
      </section>
      <section id="top-nav-support">
        <TopNav />
      </section>
      <section id="support-tile-container">
        <Overview setSelection={setSelection} />
        {selection}
      </section>
    </div>
  );
}

export default SupportPage;
