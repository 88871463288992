/**
 * May 15 Andrew: Move the users dropdown to the project detail tile.
 * Some final touches in the project detail tile.
 * Renamed the CSS file for convenience.
 */

import React, { useEffect, useState } from "react";

import "./styles/projectDetailTile.css";

function ProjectDetailTile({
  projectName,
  cost,
  sprinklered,
  height,
  existingFeet,
  proposedFeet,
  location,
}) {
  function setVariables(e) {
    e.preventDefault();
    console.log("this is e in setVariables", e);
  }

  return (
    <div id="create-project-tile-container">
      <h3 id="project-detail-title">Project Details</h3>
      <form id="create-project-form" onSubmit={setVariables}>
        <div className="div-container">
          <input
            type="text"
            id="project-name-field"
            placeholder="Project Name"
            onChange={(event) => projectName(event.target.value)}
          />
          <input
            type="text"
            id="estimated-cost-field"
            placeholder="Cost Estimate of Scope"
            onChange={(event) => cost(event.target.value)}
          />
        </div>
        <div className="div-container">
          <input
            type="text"
            id="existing-square-feet-field"
            placeholder="Existing Square Feet"
            onChange={(event) => existingFeet(event.target.value)}
          />
          <input
            type="text"
            id="proposed-square-feet-field"
            placeholder="Proposed Sqft (not including existing sqft)"
            onChange={(event) => proposedFeet(event.target.value)}
          />
        </div>
        <div id="sprinklered-height-container" className="div-container">
          <input
            type="text"
            id="text-location"
            placeholder="Location"
            onChange={(event) => location(event.target.value)}
          />
          <input
            type="text"
            id="building-height-field"
            placeholder="Building Height"
            onChange={(event) => height(event.target.value)}
          />
        </div>
        <div className="div-container">
          <span id="sprinklered-radio-container">
            <h6 id="sprinklered-title">Sprinklered?</h6>
            <span id="radio-container">
              <input
                type="radio"
                name="radio"
                id="radio-yes"
                onChange={() => sprinklered(true)}
              />
              <label for="radio-yes" id="radio-yes-label">
                Yes
              </label>
              <input
                type="radio"
                name="radio"
                id="radio-no"
                onChange={() => sprinklered(false)}
              />
              <label for="radio-no" id="radio-no-label">
                No
              </label>
            </span>
          </span>
        </div>
        {/*
        TODO add this functionality later, removing for now to simplify
        <Select
          id="tags-searchable-dropdown"
          isMulti
          placeholder="Tags"
          onChange={(event) => tags(event.target.value)}
        />
        <Select
          id="users-dropdown"
          placeholder="Users"
          isMulti
          onChange={(event) => users(event.target.value)}
        />
        */}
      </form>
    </div>
  );
}

export default ProjectDetailTile;
