import './styles/documents.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import Navbar from '../../components/Navbar/navbar.js';
import TopNav from '../../components/Navbar/topnav.js';
import Preview from '../../components/Plancheck/preview.js';
import Loading from '../../components/Loading/loading.js';

const Doc = () => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const { projectId } = state;

  useEffect(() => {
    console.log('inside of use effect');

    axios
      .get('https://buildpermit.ai:4000/docs', {
        withCredentials: true,
        params: { projectCountId: projectId, docType: 'construction' },
        responseType: 'blob',
      })
      .then((res) => {
        console.log('got res result');
        const pdfUrl = URL.createObjectURL(res.data);
        setSelectedPdf(pdfUrl);
      })
      .catch((error) => {
        console.log('unable to connect to backend', error);
        alert('unable to retreive document from our servers');
      })
      .finally(() => {
        setLoading(false);
        document.getElementById('const-doc-toggle').style.color = '#6950e8';
        document.getElementById('supp-doc-toggle').style.color = '#6b7280';
        document.getElementById('const-doc-toggle').style.borderBottom =
          '1px solid #6950e8';
        document.getElementById('supp-doc-toggle').style.border = 'none';
      });
  }, [projectId]);

  function changeDocType(type) {
    const constEle = document.getElementById('const-doc-toggle');
    const suppEle = document.getElementById('supp-doc-toggle');
    if (type.target.id === 'const-doc-toggle') {
      constEle.style.color = '#6950e8';
      suppEle.style.color = '#6b7280';
      constEle.style.borderBottom = '1px solid #6950e8';
      suppEle.style.border = 'none';
      getDoc('construction');
    }
    if (type.target.id === 'supp-doc-toggle') {
      suppEle.style.color = '#6950e8';
      constEle.style.color = '#6b7280';
      suppEle.style.borderBottom = '1px solid #6950e8';
      constEle.style.border = 'none';
      getDoc('supplement');
    }
  }

  function getDoc(type) {
    setLoading(true);

    axios
      .get('https://buildpermit.ai:4000/docs', {
        withCredentials: true,
        params: { projectCountId: projectId, docType: type },
        responseType: 'blob',
      })
      .then((res) => {
        const pdfUrl = URL.createObjectURL(res.data);
        setSelectedPdf(pdfUrl);
      })
      .catch((error) => {
        console.log('unable to connect to backend', error);
        alert('unable to retreive document from our servers');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div id="document-window">
      <section id="document-window-navbar">
        <Navbar />
      </section>
      <section id="document-window-topnav">
        <div id="document-window-topnav-inner">
          <TopNav />
        </div>
      </section>
      <section id="document-window-section">
        <div id="inner-document-window-container">
          <div id="choose-doc-type-container">
            <p
              className="doc-type-toggle"
              id="const-doc-toggle"
              onClick={changeDocType}
            >
              Construction
            </p>
            <p
              className="doc-type-toggle"
              id="supp-doc-toggle"
              onClick={changeDocType}
            >
              Supplement
            </p>
          </div>
          <div id="loading-preview-container">
            {loading ? (
              <Loading subject={'loading documents'} />
            ) : (
              <Preview
                selectedPdf={selectedPdf}
                id="document-window-preview-component"
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Doc;
