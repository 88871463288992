/* Author: Aaron Kann  
   Email: aaron@stevek.com*/

import './styles/inbox.css';
import axios from 'axios';
import profile from '../../images/samples/profile.png';
import React, { useState } from 'react';

//testcases : some sample messages to test the functionality
const sampleMessages = [
  {
    date: 'December 10, 2020',
    from: 'David',
    subject: 'Status on permit',
    to: 'John',
    cc: 'john@citmit.ai',
    body: 'Hey I have an update on the status of the permit, give me a call when you have the chance',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
  {
    date: 'December 10, 2020',
    from: 'Peter Griffin',
    subject: 'Stealing my identity',
    to: 'Spiderman',
    cc: 'Batman',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultricies, nunc nunc ultricies nunc, vitae ultric',
  },
];

// // REWRITE ALL THE PREVIOUS CODE WITHOUT USING CREATEELEMENT
// const domContainer = document.getElementById('root');
// const root = ReactDOM.createRoot(domContainer);
// root.render(<InboxPage messages={sampleMessages} selectedMessage={null} />);

function InboxHeader(props) {
  const { setNewMessage } = props;

  const handleItemClick = () => {
    console.log('new message clicked');
    setNewMessage(true);
  };

  return (
    <div className="inbox-header">
      <h1 className="inbox-header-title">Inbox</h1>
      <div className="inbox-header-buttons">
        <button
          className="inbox-header-button"
          id="new-message-button"
          onclick={handleItemClick}
        >
          New Message
        </button>
        <button className="inbox-header-button">Delete</button>
      </div>
    </div>
  );
}

function InboxList(props) {
  const { messages, selMessage, setSelMessage } = props;

  const handleItemClick = (message) => {
    if (!(selMessage === message)) {
      setSelMessage(message);
    }
  };

  return (
    <div className="inbox-list">
      <ul className="inbox-list-ul">
        {messages.map((message) => (
          <InboxItem
            key={message.date}
            message={message}
            isSelected={selMessage === message}
            onItemClick={handleItemClick}
          />
        ))}
      </ul>
    </div>
  );
}

function InboxItem(props) {
  const { message, isSelected, onItemClick } = props;
  console.log(isSelected, message);

  return (
    <div
      className={`inbox-item ${isSelected ? 'selected' : ''}`}
      onClick={() => onItemClick(message)}
    >
      <div className="inbox-date-and-square">
        <input type="checkbox" className="inbox-square" />
        <p className="inbox-date">{message.date}</p>
      </div>
      <div className="inbox-message-info">
        <input type="checkbox" className="inbox-circle" />
        <h3 className="inbox-sender">{props.message.from}</h3>
        <p className="inbox-subject">{props.message.subject}</p>
      </div>
    </div>
  );
}

// function InboxList(props) {
//   console.log(props.messages);
//   return (
//     <div className="inbox-list">
//       <ul className="inbox-list-ul">
//         {props.messages.map((message) => (
//           <InboxItem message={message} />
//         ))}
//       </ul>
//     </div>
//   );
// }

// // include the
// function InboxItem(props) {
//   console.log(props);
//   return (
//     <div className="inbox-item">
//       <div className="inbox-date-and-square">
//         <input type="checkbox" className="inbox-square"></input>
//         <p className="inbox-date">{props.message.date}</p>
//       </div>
//       <div className="inbox-message-info">
//         <input type="checkbox" className="inbox-circle"></input>
//         <h3 className="inbox-sender">{props.message.from}</h3>
//         <p className="inbox-subject">{props.message.subject}</p>
//       </div>
//     </div>
//   );
// }

// NOTE: I think at some point we will have some sort of
// user data structure that contains image, position, name, etc.
// For now, they are passed in as props
function SelectedMessage(props) {
  const { selMessage, newMessage } = props;

  console.log('selected message', props.selMessage);
  console.log('new message', newMessage);
  if (newMessage) {
    return <div id="selected-message"></div>;
  }

  if (selMessage == null) {
    return <div id="selected-message"></div>;
  }

  return (
    <div id="selected-message">
      <div className="selected-message-image">
        <img src={profile} alt="Profile Image" />
      </div>
      <div className="selected-message-header">
        <div className="selected-message-header-from">
          <span className="selected-message-header-sender">
            {props.selMessage.from},{' '}
          </span>
          <span className="selected-message-header-sender-position">CEO</span>
        </div>
        <div className="selected-message-header-date">
          Freedom West Apartments
        </div>
        <div className="selected-message-header-reciever">
          <span className="selected-message-header-to">
            to: {props.selMessage.to}
          </span>
          <span className="selected-message-header-cc">
            , cc: {props.selMessage.cc}
          </span>
        </div>
      </div>
      <div className="selected-message-body">
        <p className="selected-message-body-text">{props.selMessage.body}</p>
      </div>
    </div>
  );
}

function InboxPage() {
  const [messages, setMessages] = React.useState([]);
  const [selMessage, setSelMessage] = React.useState(null);
  const [newMessage, setNewMessage] = React.useState(false);

  const handleNewMessageClick = () => {
    setNewMessage(true);
  };

  React.useEffect(() => {
    getData();
  }, []); // Empty dependency array ensures that the effect runs only once on page load

  function getData() {
    axios
      .get('https://buildpermit.ai:4000/inbox', { crossdomain: true })
      .then((response) => {
        setMessages(response.messages);
        setSelMessage(response.messages[0]);
      })
      .catch((error) => {
        console.log('we made it to SetProjects');
        // console.error(error); FOR NOW, IGONORE THIS
        setMessages(sampleMessages);
        setSelMessage(sampleMessages[0]);
        // setNewMessage(false);
      });
  }

  return (
    <div className="inbox-page">
      <InboxHeader setNewMessage={setNewMessage} />
      <InboxList
        messages={messages}
        selMessage={selMessage}
        setSelMessage={setSelMessage}
      />
      <SelectedMessage selMessage={selMessage} newMessage={newMessage} />
    </div>
  );
}
export default InboxPage;
