/*
 * May 5, Raymond: created this page just now. still need to work on css
 * havent tested to see if it works yet
 *
 */
import './styles/forgotPassword.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Accountrecovery from '../../components/Login/forgotPassword';

function AccountRecovery() {
  const [userEmail, setUserEmail] = useState('');

  return (
    <div id="account-recovery-container">
      <Accountrecovery />
    </div>
  );
}

export default AccountRecovery;
