import React from 'react';
import { useState } from 'react';
import './styles/dropzone.css';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Preview from './preview.js';

const FileDropzone = ({ onPdfSelect }) => {
  const [dropText, setDropText] = useState(
    'Drag and drop a PDF file here, or click to select one'
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    onPdfSelect(file);
    const formData = new FormData();
    formData.append('file', file);
    setUploadedFile(file);
    setSelectedPdf(file);
    document.getElementById('dropContainer').style.border = 'none';

    // need to connect to backend using formdata
    axios
      .post('https://buildpermit.ai:4000/file-upload', formData)
      .then((res) => {
        if (res.status === 200) {
          console.log(
            'this is res.file that was sent from the backend:',
            res.data.file
          );
          sessionStorage.setItem('file_id', res.data.file_id);
          sessionStorage.setItem('file_name', res.data.name);
        }
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop,
  });

  return (
    <div id="dropContainer">
      {!uploadedFile ? (
        <section {...getRootProps()} className="root">
          <input {...getInputProps()} />
          <div id="droptext">
            <p>{dropText}</p>
          </div>
        </section>
      ) : (
        <div id="pdfContainer">
          <Preview selectedPdf={selectedPdf} />
        </div>
      )}
    </div>
  );
};

export default FileDropzone;

/*
 * THIS MAKES AN ACTUAL PDF READER INSIDE THE COMPONENT, WORKS BUT DON'T WANT
 *
          <embed
            src={URL.createObjectURL(uploadedFile)}
            type="application/pdf"
            width="100%"
            height="100%"
          />
*/
