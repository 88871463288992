/*
 * May 8, Raymond: need to reach the backend and retreive all active projects.
 * Haven't implemented anything
 */
import './styles/activeProjects.css';
import React, { useMemo, useState } from 'react';
import axios from 'axios';
import { Checked, OnHold, Pending } from './Status/status.js';
import { useNavigate } from 'react-router-dom';

import Loading from '../Loading/loading.js';
import DisplayNoProjects from './noDisplay.js';
import YesNoPopup from '../PopupWindow/yesNoPopup.js';

import editPencil from '../../images/edit_icon.svg';

function ActiveProjects({ setNumActive, setNumDraft }) {
  const [hasProjects, setHasProjects] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState(null);
  const [deleted, setDeleted] = useState(true);
  const [header, setHeader] = useState();
  const [projectId, setProjectId] = useState();
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  let showProjects = [];

  useMemo(() => {
    if (deleted) {
      setDeleted(false);
    }

    function getProjects() {
      axios
        .get('https://buildpermit.ai:4000/projects', {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.projects.length !== 0) {
              setProjects(response.data.projects);
              setHasProjects(true);
            } else {
              setHasProjects(false);
            }
          } else {
            setProjects(null);
            setHasProjects(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setProjects(null);
          setHasProjects(false);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    }

    getProjects();
  }, [deleted]);

  // TODO add projects to session storage to make it easier to retrieve details
  function DisplayProjects({ setNumActive, setNumDraft }) {
    // component to build each project and show it on the projects page
    function EachProject(props) {
      // get the status from status component
      function GetStatus(props) {
        const statusString = props.status.toLowerCase();
        if (statusString === 'checked') {
          return <Checked />;
        } else if (statusString === 'pending') {
          return <Pending />;
        } else {
          return <OnHold />;
        }
      }

      function goToProject(event) {
        const projectId = event.currentTarget.id;
        const path = `/projects/document#${projectId}`;
        navigate(path, {
          state: { projectId: projectId },
        });
      }

      function editProject(event) {
        event.stopPropagation();
        const projectId = event.currentTarget.id;
        const path = `/projects/edit#${projectId}`;
        navigate(path, {
          state: { projectId: projectId },
        });
      }

      return (
        <div
          className="each-project-container"
          id={props.info.project_count_id}
          onClick={goToProject}
          name={props.info.project_name}
        >
          <span className="each-project-header">{props.info.project_name}</span>
          <span className="each-project-location">{props.info.location}</span>
          <span className="each-project-id">
            #{props.info.project_count_id}
          </span>
          <span className="each-project-client">{props.info.client}</span>
          <span className="each-project-review-cost">
            ${props.info.cost_estimate}
          </span>
          <span className="each-project-fee-percent">20%</span>
          <span className="each-project-status">
            {<GetStatus status={props.info.status} />}
          </span>
          <span className="each-project-edit-buttons">
            <img
              src={editPencil}
              alt="edit pencil"
              className="edit-button-clickable"
              id={props.info.project_count_id}
              onClick={editProject}
            />
          </span>
        </div>
      );
    }

    let activeCount = 0;
    let draftCount = 0;

    if (projects) {
      // create components for each project
      showProjects = projects.map((data, key) => {
        if (!data.draft) {
          setActive(true);
          activeCount += 1;
          return <EachProject info={data} key={key} />;
        } else {
          draftCount += 1;
          return <span className="empty-span" />;
        }
      });
    }

    setNumActive(activeCount);
    setNumDraft(draftCount);

    //TODO add edit title after edit functionality is added
    return (
      <span>
        {active ? (
          <div id="display-projects-container">
            <section id="pi-nav-legend-container">
              <p className="pi-nav-legend">Project</p>
              <p className="pi-nav-legend">Location</p>
              <p className="pi-nav-legend">ID</p>
              <p className="pi-nav-legend">Client</p>
              <p className="pi-nav-legend">Review Cost</p>
              <p className="pi-nav-legend">Fee Percent</p>
              <p className="pi-nav-legend">Status</p>
              <p className="pi-nav-legend">Edit</p>
            </section>
            <section id="display-each-project-container">
              {showProjects}
            </section>
          </div>
        ) : (
          <div id="no-active-projects-container">
            <h3 id="no-active-projects-header">No Active Projects</h3>
            <p id="no-active-projects-description">
              Click on <span id="no-active-draft">Draft</span> to view pending
              projects
            </p>
          </div>
        )}
      </span>
    );
  }

  return (
    <div id="active-projects-container">
      {loading ? (
        <Loading subject={'loading active projects'} />
      ) : hasProjects ? (
        <div>
          <div id="display-delete-project-popup">
            <YesNoPopup
              header={header}
              projectId={projectId}
              setDeleted={setDeleted}
            />
          </div>
          <DisplayProjects
            setNumActive={setNumActive}
            setNumDraft={setNumDraft}
          />
        </div>
      ) : (
        <DisplayNoProjects />
      )}
    </div>
  );
}

export default ActiveProjects;
