/**
 * May 16 Andrew: added options for permit type
 *
 */

import React, { useState } from 'react';
import Select from 'react-select';

import './styles/additionalDetails.css';

function AdditionalDetailsTile({
  parcel,
  acres,
  zoning,
  occupancy,
  client,
  permitType,
  description,
}) {
  const [value, setValue] = useState('');
  const permitTypes = [
    { value: 'null', label: 'Permit type' },
    {
      value: 'adu',
      label: 'Accessory Dwelling Unit (ADU)',
    },
    {
      value: 'residential-new-buildings-and-additions',
      label: 'Residential New Buildings & Additions',
    },
    {
      value: 'residential-remodels-and-exterior-changes',
      label: 'Residential Remodels & Exterior Changes',
    },
    {
      value: 'residential-remodels-with-no-exterior-changes',
      label: 'Residential Remodels With No Exterior Changes',
    },
    {
      value: 're-stucco-or-siding-only',
      label: 'Re-Stucco/Siding Only',
    },
    {
      value: 'swimming-pools-or-spa-only',
      label: 'Swimming Pools/Spa Only',
    },
    {
      value: 'fences-walls-retaining-walls',
      label: 'Fences/Walls/Retaining Walls',
    },
    {
      value: 'patio-trellis-bbq-decks-other-outside-features',
      label: 'Patio, Trellis, BBQ, Decks, and Other Outside Features',
    },
    {
      value: 'Foundation-seismic-upgrade-pre-approved-plans',
      label: 'Foundation Seismic Upgrade Pre-Approved Plans',
    },
    {
      value: 're-roof',
      label: 'Re-Roof',
    },
    {
      value: 'demolition',
      label: 'Demolition',
    },
    {
      value: 'commercial-new-buildings-and-additions',
      label: 'Commercial New Buildings & Additions',
    },
    {
      value: 'commercial-tanant-improvement',
      label: 'Commercial Tenant Improvement',
    },
    {
      value: 'commercial-signs',
      label: 'Commercial Signs',
    },
    {
      value: 'wireless-telecomm-facility',
      label: 'Wireless Telecommunications Facility',
    },
    {
      value: 'Mechanical-electrical-service-upgrade-or-plumbing',
      label: 'Mechanical, Electrical/Service Upgrade or Plumbing',
    },
    {
      value: 'electric-vehicle-charging-station',
      label: 'Electric Vehicle Charging Station',
    },
    {
      value: 'solar-photovoltaic-system',
      label: 'Solar Photovoltaic System',
    },
    {
      value: 'grading-plan',
      label: 'Grading Plan',
    },
    {
      value: 'public-property',
      label: 'Public Property (“Right of Way”) - Sidewalk/Driveway/Excavation',
    },
    {
      value: 'haul-route-permit-and-others',
      label: 'Haul Route Permit/Oversize Vehicle Permit/Street Use Permit',
    },
    {
      value: 'special-event-permit',
      label: 'Special Event Permit',
    },
    {
      value: 'fire-department-submittals',
      label: 'Fire Department Submittals',
    },
    {
      value: 'bmod',
      label: 'Building Modification Form (BMOD)',
    },
    {
      value: 'indigenous-tree-permit',
      label: 'Indigenous Tree Permit',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  function submitForm(e) {
    e.preventDefault();
  }

  return (
    <div id="additional-detail-tile-container">
      <h3 id="additional-detail-title">Additional Details</h3>
      <form id="additional-detail-form" onSubmit={submitForm}>
        <div>
          <input
            id="parcel-number-field"
            placeholder="Parcel Number"
            onChange={(event) => parcel(event.target.value)}
          />
          <input
            id="arces-input-field"
            placeholder="Arces"
            onChange={(event) => acres(event.target.value)}
          />
          <input
            id="zoning-input-field"
            placeholder="Zoning"
            onChange={(event) => zoning(event.target.value)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="occupancy-type-field"
            placeholder="Occupancy Type"
            onChange={(event) => occupancy(event.target.value)}
          />
          <input
            id="client-field"
            placeholder="Client"
            onChange={(event) => client(event.target.value)}
          />
          <select
            id="permit-type-dropdown"
            placeholder="Permit Type"
            onChange={(event) => permitType(event.target.value)}
          >
            {permitTypes.map((type) => (
              <option
                key={type.value}
                value={type.value}
                className="select-permit-type"
              >
                {type.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label id="project-description-label">Project Description</label>
          <br />
          <textarea
            id="project-description-field"
            onChange={(event) => description(event.target.value)}
          />
        </div>
      </form>
    </div>
  );
}

export default AdditionalDetailsTile;
