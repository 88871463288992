/**
 * Author: Aaron Kann
 * aaron@stevek.com
 */

import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/navbar.js';
import Topnav from '../../components/Navbar/topnav.js';
import UserProfile from '../../pages/Users/UserProfile.js';
import UserForm from '../../pages/Users/UserForm.js';
import './styles/userPage.css';

function UserPage() {
  const [userId, setUserId] = useState(1);
  const [hasEditingPowers, setHasEditingPowers] = useState(true);
  const [userObject, setUserObject] = useState({}); //for now, i will assume backend gives me user data as an object

  React.useEffect(() => {
    setUserObject(getData(userId)); //TODO: implement this
  }, []); //empty array means this effect will only run once, after the first render

  return (
    <div id="user-container">
      <Navbar />
      <div id="page">
        <Topnav />
        <div id="project-tiles-container">
          {userObject != {} && <UserProfile user={userObject} />}
          {hasEditingPowers && <UserForm user={null}/>}
        </div>
      </div>
    </div>
  );

  function getData(userId) {
    //TODO: implement this
    return joe;
  }
}

// sample user (for testing)
const joe = {
  backgroundImg: 'https://www.w3schools.com/howto/img_snow_wide.jpg',
  profileImg: 'https://www.w3schools.com/howto/img_avatar.png',
  userName: 'Joe Schmoe',
  userRole: 'Software Engineer',
  userLocation: 'San Francisco, CA',
  userJoinDate: 'April 2021',
};

export default UserPage;

