import './styles/noDisplay.css';
import React, { useState } from 'react';

function DisplayNoProjects() {
  return (
    <div id="no-projects-container">
      <h1 id="no-projects-header">No Projects</h1>
      <h2 id="no-projects-description">
        Click on &#43;Add Project to start a new project
      </h2>
    </div>
  );
}

export default DisplayNoProjects;
