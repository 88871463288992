/**
 * Author: Aaron Kann
 * aaron@stevek.com
 */
import React, { useEffect, useState } from 'react';
import edit from '../../images/icons/Edit.svg';
import './styles/userProfile.css';

/**
 * User Profile (top of user Page)
 * @param {*} props, an object with the following properties:
 * props.backgroundImg
 * props.profileImg
 * props.userName
 * props.userRole
 * props.userLocation
 * props.userJoinDate
 * @returns React component for user profile
 */
function UserProfile(props) {
    const userObj = props.user;

    const backgroundImg = userObj.backgroundImg;
    const profileImg = userObj.profileImg;
    const userName = userObj.userName;
    const userRole = userObj.userRole;
    const userLocation = userObj.userLocation;
    const userJoinDate = userObj.userJoinDate;

    return (
    <div id="UserProfile">
        <img id="userBackgroundImg" src={backgroundImg}></img>
        <img id="userProfileImg" src={profileImg}></img>
        <img id="editButton" src={edit}></img>
        <h1 id="userName"> {userName} </h1>
        <div id="userBio">
            <img id="roleIcon"></img>
            <p id="role">{userRole}</p>
            <img id="locationIcon"></img>
            <p id="location">{userLocation}</p>
            <img id="calendarIcon"></img>
            <p id="joinDate">Joined {userJoinDate}</p>
        </div>
        <div id="buttons">
            <button id="followButton">Follow</button>
            <button id="messageMeButton">Message</button>
            <button hidden id="miscButton">...</button>
        </div>
    </div>
    );
}



export default UserProfile;