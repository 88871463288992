import './styles/loading.css';
import React, { useEffect, useState } from 'react';

function Loading({ subject }) {
  const [sub, setSub] = useState();

  useEffect(() => {
    if (subject) setSub(subject);
    else setSub('loading, please wait...');
  }, [subject]);

  return (
    <div id="loading-projects-container">
      <h3 id="loading-projects-header">{sub}</h3>
    </div>
  );
}

export default Loading;
