/*
 * May 9, Raymond: made topnav its own component for easier
 * use on all pages
 */
import './styles/project.css';
import React from 'react';
import Navbar from '../../components/Navbar/navbar.js';
import ProjectsTile from '../../components/Project/projectTiles.js';
import TopNav from '../../components/Navbar/topnav.js';

function Project() {
  return (
    <div id="project-container">
      <section id="main-navbar-projects">
        <Navbar currentPage={'projects'} />
      </section>
      <section id="top-nav-projects">
        <TopNav />
      </section>
      <section id="projects-tiles-container">
        <ProjectsTile id="projects-tile-projects" />
      </section>
    </div>
  );
}

export default Project;
