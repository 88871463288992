/**
 * May 15 Andrew: Only accepts pdf files and final touches to the file upload tile.
 */

import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import IconButton from "@mui/material/IconButton";

import "./styles/fileUploadTile.css";

function SupplementalFileUpload({ suppDocs }) {
  const [supfiles, setSupFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setSupFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    const file = acceptedFiles[0];
    suppDocs(file);
  }, []);
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    noClick: supfiles.length > 0,
  });

  const handleDelete = (file) => {
    const newFiles = supfiles.filter((f) => f !== file);
    setSupFiles(newFiles);
  };

  const renderFiles = () => {
    return supfiles.map((file) => {
      return (
        <li id="file-list-containter">
          <p id="file-name-uploader">{file.name}</p>
          <IconButton
            id="delete-icon-button"
            size="small"
            onClick={() => handleDelete(file)}
          >
            <DeleteIcon id="delete-button-file" />
          </IconButton>
        </li>
      );
    });
  };

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    setSupFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  return (
    <div id="file-upload-tile-container">
      <div id="file-uploader" {...getRootProps()}>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
          {...getInputProps()}
        />
        {supfiles.length === 0 ? (
          <>
            <UploadFileRoundedIcon id="upload-file-icon" />
            <p>
              Drop your Supplemental Documents here (applications, forms,
              calculations, etc.)
            </p>
            <p id="file-upload-button">Click to Browse</p>
          </>
        ) : (
          <>
            <div id="render-file-container">{renderFiles()}</div>
            <button id="file-add-button" onClick={open}>
              Add Files
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default SupplementalFileUpload;
