export const faq_list = [
  {
    question: 'How to create a new project?',
    answer: (
      <p className="faq-answers">
        1) Click on the Projects tab on the sidebar.
        <br />
        2) In the Projects page, on the upper right hand corner of the tile you
        will see +Add Project, click it.
        <br />
        3) From there you fill out all the necessary information.
        <br />
        <br />
        If you don't have all the information on hand you could simply save as
        draft and come back to it at another time.
        <br /> After you fill out all of the needed information, you can submit
        the project for review
      </p>
    ),
  },
  {
    question: 'How to submit an application?',
    answer: (
      <p className="faq-answers">
        There are several ways
        <br /> 1) While creating a new project, after you fill everything out
        you will see a green "Submit Project" button appear at the bottom of the
        screen. Once you click it and everything is saved on our servers you
        will then be prompted to checkout. After the checkout process is
        complete, your application will be automatically submitted for review{' '}
        <br />
        2)If you choose to save your project as a draft, after you fill out all
        of the information and finish editing then you will see a green
        "Checkout" button appear at the bottom of the screen. You can checkout
        there and after checkout is complete your application will be
        automatically submitted for review.
        <br /> 3)If you project is ready for checkout, you will see a "Ready to
        Checkout!" status appear next to your project, you can click on that
        status as well to complete the checkout. Once checkout is complete your
        application will be automatically submitted for review.
      </p>
    ),
  },
  {
    question: 'What is Status?',
    answer: (
      <p className="faq-answers">
        The status of your project gives you information on how your project is
        coming along as well as how your application is going.
        <br /> There are 5 main statuses
        <br /> <strong>1) In Progress</strong>
        &emsp; Your project is in the draft process and still requires more
        information in order to submit
        <br /> <strong>2) Ready to Checkout</strong> &emsp; Your project has all
        the necessary information and you are ready to submit a new application{' '}
        <br /> <strong>3) Pending</strong> &emsp; Your application is under
        review <br /> <strong>4) Checked</strong>
        &emsp; The review process is complete and you are ready to download
        <br /> <strong>5) On Hold</strong>
        &emsp; Our review team has found an error in your application that
        requires attention
      </p>
    ),
  },
];
