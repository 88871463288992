import "./styles/userListTile.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EachUserList from "./EachUserList";

function UserListTile() {
  const [users, setUsers] = useState("all-users-toggle");
  const [previous, setPrevious] = useState("all-users-toggle");
  const navigate = useNavigate();

  function toggle(event) {
    event.preventDefault();
    setPrevious(users);
    setUsers(event.target.id);
  }

  // used to change the colors and borders of user type
  // everytime one is clicked
  useEffect(() => {
    const current = document.getElementById(users);
    current.style.borderBottom = "1px solid #6950e8";
    current.style.color = "#6950e8";
    const prev = document.getElementById(previous);
    if (previous !== users) {
      prev.style.border = "none";
      prev.style.color = "#6b7280";
    }
  }, [users]);

  function handleAddUser() {
    navigate("/users/create");
  }

  const dummyData = [
    {
      name: "Andrew Park",
      username: "7174Andy",
      email: "hee031011@gmail.com",
      role: "Architech",
    },
    {
      name: "Julian Wan",
      username: "julian-wan",
      email: "julian-wan@gmail.com",
      role: "Engineer",
    },
    {
      name: "Christian Buehner",
      username: "christian-buehner",
      email: "christian-buehner@gmail.com",
      role: "City Planner",
    },
  ];

  return (
    <div id="user-list-tile-container">
      <section id="top-line-user-list-tile">
        <div id="user-list-header-container">
          <h2 id="user-list-tile-header">Users</h2>
        </div>
        <div id="user-list-type-chooser-container">
          <a
            href="#all-users"
            className="user-list-toggle"
            id="all-users-toggle"
            onClick={toggle}
          >
            All Users
          </a>
          <a
            href="#editor"
            className="user-list-toggle"
            id="editor-toggle"
            onClick={toggle}
          >
            Editor
          </a>
          <a
            href="#contributor"
            className="user-list-toggle"
            id="contributor-toggle"
            onClick={toggle}
          >
            Contributor
          </a>
          <a
            href="#author"
            className="user-list-toggle"
            id="author-toggle"
            onClick={toggle}
          >
            Author
          </a>
          <a
            href="administrator"
            className="user-list-toggle"
            id="administrator-toggle"
            onClick={toggle}
          >
            Administrator
          </a>
          <a
            href="subscriber"
            className="user-list-toggle"
            id="subscriber-toggle"
            onClick={toggle}
          >
            Subscriber
          </a>
        </div>
        <button id="user-list-tile-add-user" onClick={handleAddUser}>
          &#43; Add New User
        </button>
      </section>
      <section id="user-list-tile-tags">
        <p className="user-list-each-tag">Name</p>
        <p className="user-list-each-tag">Username</p>
        <p className="user-list-each-tag">Email</p>
        <p className="user-list-each-tag">Role</p>
        <p className="user-list-each-tag">Edit</p>
      </section>
      <section id="all-users-tile-container">
        <div id="inner-all-users-tile-container">
          {dummyData.map((user) => {
            return (
              <EachUserList
                name={user.name}
                username={user.username}
                email={user.email}
                role={user.role}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default UserListTile;
