import './styles/navbar.css';
import citmitLogo from '../../images/logos/Citmit2023.svg';
import citmitLogoSmall from '../../images/icons/CITMIT_SMALL.svg';
import mail from '../../images/icons/mail.svg';
// import help from '../../images/icons/new_help.svg';
// import calendar from '../../images/icons/new_calendar.svg';
import account from '../../images/samples/placeholder.png';
import dash from '../../images/icons/dash.svg';
import logout from '../../images/icons/logout.svg';
import Dashboard from '../../pages/Dashboard/dashboard';
import calendar from '../../images/icons/e.svg';
import help from '../../images/icons/d.svg';
import projects from '../../images/icons/b.svg';
import a from '../../images/icons/a.svg';
import { useNavigate, redirect } from 'react-router-dom';
import axios from 'axios';
import { useMemo } from 'react';

//create a sidebar to be generated in React alongside whatever is on the display page
function Navbar({ currentPage }) {
  const navigate = useNavigate();

  // preset navbar highlight
  useMemo(() => {
    if (currentPage) {
      console.log('current page', currentPage);
      const buttons = document.querySelectorAll('button');
      buttons.forEach((button) => {
        button.classList.remove('selected');
      });

      const select = document.getElementById(currentPage);
      if (select) select.classList.add('selected');
    }
  }, [currentPage]);

  const handleButtonClick = (buttonId) => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => {
      button.classList.remove('selected');
    });

    const button = document.getElementById(buttonId);
    button.classList.add('selected');

    navigate(`/${buttonId}`);
  };

  function logoutAccount() {
    axios
      .get('https://buildpermit.ai:4000/logout', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 204) {
          console.log('logout successful');
          localStorage.removeItem('rememberToken');
        } else {
          console.log('unable to log out');
          console.log(res);
        }
      })
      .catch((error) => {
        console.error('unable to connect to citmit servers', error);
      })
      .finally(() => {
        navigate('/');
      });
  }

  return (
    <div id="sidebarDiv">
      <section id="sidebar">
        <img id="citmitLogo" src={citmitLogo} alt="citmit logo"></img>
        <img
          id="citmitLogoSmall"
          src={citmitLogoSmall}
          alt="citmit logo small"
        ></img>
        <div id="topbuttons">
          {/* <a href="/dashboard"> */}
          {/*
          <button id="dash" onClick={() => handleButtonClick('dashboard')}>
            <span class="blueBar"></span>
            <span class="blueBox">
              <img
                class="turnGrey"
                id="dashImg"
                src={dash}
                alt="dashboard"
              ></img>
              <p class="turnGrey">Dashboard</p>
            </span>
          </button>
          {/* </a> */}
          {/* <a href="/profile"> */}
          <button id="projects" onClick={() => handleButtonClick('projects')}>
            <span className="blueBar"></span>
            <span className="blueBox">
              <img
                className="turnGrey"
                id="projectsImg"
                src={projects}
                alt="projects"
              ></img>
              <p className="turnGrey">Projects</p>
            </span>
          </button>
          {/* </a> */}
          {/* <a href="/profile"> */}
          {/*
          <button id="user" onClick={() => handleButtonClick('user')}>
            <span class="blueBar"></span>
            <span class="blueBox">
              <img class="turnGrey" id="usersImg" src={a} alt="users"></img>
              <p class="turnGrey">Users</p>
            </span>
          </button>
          {/* </a> */}
          {/* <a href="/calendar"> */}
          {/*
          <button id="calendar" onClick={() => handleButtonClick('calendar')}>
            <span class="blueBar"></span>
            <span class="blueBox">
              <img
                class="turnGrey"
                id="calendarImg"
                src={calendar}
                alt="calendar"
              ></img>
              <p class="turnGrey">Calendar</p>
            </span>
          </button>
          {/* </a> */}
          {/* <a href="/inbox"> */}
          {/*
          <button id="inbox" onClick={() => handleButtonClick('inbox')}>
            <span class="blueBar"></span>
            <span class="blueBox">
              <img class="turnGrey" id="inboxImg" src={mail} alt="inbox"></img>
              <p class="turnGrey">Inbox</p>
            </span>
          </button>
          {/* </a> */}
          {/* <a href="/help"> */}
          <button id="help" onClick={() => handleButtonClick('help')}>
            <span className="blueBar"></span>
            <span className="blueBox">
              <img
                className="turnGrey"
                id="helpImg"
                src={help}
                alt="help"
              ></img>
              <p className="turnGrey">Support</p>
            </span>
          </button>
          {/* </a> */}
        </div>
        <div id="bottombuttons">
          <button id="logout" onClick={logoutAccount}>
            <span className="blueBar"></span>
            <span className="blueBox">
              <img
                className="turnGrey"
                src={logout}
                alt="logout"
                id="logoutimg"
              />
              <p className="turnGrey">Logout</p>
            </span>
          </button>
        </div>
      </section>
      <section hidden id="secondSidebar"></section>
    </div>
  );
}

export default Navbar;
