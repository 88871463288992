/*
 * May 9, Raymond: made a separate for the top nav
 * still need to implement todo's
 *
 * TODO give funtionality to notification bell. show notifications
 * and also show how many notifications you have
 *
 * TODO implement functionality to toggle night mode
 */
import './styles/topnav.css';
import React from 'react';
import notification_bell from '../../images/notification_bell.svg';
import brightness from '../../images/brightness.svg';
import account_temp from '../../images/icons/account.svg';

function TopNav() {
  return (
    <section id="top-nav-container">
      <nav id="top-nav">
        <div className="projects-nav-img-container">
          {/*
          <img
            src={brightness}
            alt="brightness setting"
            id="projects-brightness"
          />
          */}
        </div>
        <div className="projects-nav-img-container">
          <img
            src={notification_bell}
            alt="notification bell"
            id="projects-notification"
          />
        </div>
        <a href="/profile" className="projects-nav-img-container">
          <img src={account_temp} alt="profile" id="projects-profile" />
        </a>
      </nav>
    </section>
  );
}

export default TopNav;
