// Author: Andrew Park
// Contact: Andrew.P

import React, { useState } from 'react';
import './styles/profile.css';
import Logo from '../../components/Logo/logo.js';
import image from '../../images/image.png';

const Profile = () => {
  const name = 'John Doe';
  var contact = 'This is where the contacts info will go';
  var biography = 'This is where the biography info will go';
  var links = 'This is where the links info will go';

  const [editing, setEditing] = React.useState(false);
  const [contactInfo, setContactInfo] = React.useState(contact);
  const [biographyInfo, setBiographyInfo] = React.useState(biography);
  const [linksInfo, setLinksInfo] = React.useState(links);

  const [contactInfoSaved, setContactInfoSaved] = React.useState(contact);
  const [biographyInfoSaved, setBiographyInfoSaved] = React.useState(biography);
  const [linksInfoSaved, setLinksInfoSaved] = React.useState(links);

  //save changes made in the edit
  const handleClick = () => {
    setEditing((editing) => !editing);
    setContactInfoSaved(contactInfo);
    setBiographyInfoSaved(biographyInfo);
    setLinksInfoSaved(linksInfo);
  };

  //revert changes made in the edit
  const handleCancelClick = () => {
    setEditing(false);
    setContactInfo(contactInfoSaved);
    setBiographyInfo(biographyInfoSaved);
    setLinksInfo(linksInfoSaved);
  };

  return (
    <div class="background">
      <Logo />
      <img src={image} alt="profile" className="profile-picture" />

      <div className="">
        <h2 className="profile-name">{name}</h2>
        <div>
          <h2 className="profile-header">Contact</h2>
          {!editing ? (
            <p className="profile-content">{contactInfo}</p>
          ) : (
            <form>
              <textarea
                type="text"
                className="profile-edit-input"
                defaultValue={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
              />
            </form>
          )}
        </div>
        <div>
          <h2 className="profile-header">Biography</h2>
          {!editing ? (
            <p className="profile-content">{biographyInfo}</p>
          ) : (
            <form>
              <textarea
                type="text"
                className="profile-edit-input"
                defaultValue={biographyInfo}
                onChange={(e) => setBiographyInfo(e.target.value)}
              />
            </form>
          )}
        </div>
        <div>
          <h2 className="profile-header">Links</h2>
          {!editing ? (
            <p className="profile-content">{linksInfo}</p>
          ) : (
            <form>
              <textarea
                type="text"
                className="profile-edit-input"
                defaultValue={linksInfo}
                onChange={(e) => setLinksInfo(e.target.value)}
              />
            </form>
          )}
        </div>
        <button className="edit-profile-button" onClick={handleClick}>
          {editing ? 'Save' : 'Edit Profile'}
        </button>
        {editing ? (
          <button
            className="edit-profile-cancel-button"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Profile;
