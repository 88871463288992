/*
 * TODO once the user clicks on the confirm button in their email
 * we then have to set a session for them from the email. with their info
 * is there isn't a way to do that then make them sign in after confirmation.
 */
import './styles/accountCreated.css';
import React from 'react';
import blueCheckmark from '../../images/blue-checkmark.svg';
import { useNavigate } from 'react-router-dom';

function AccountConfirm() {
  const navigate = useNavigate();

  function confirm() {
    navigate('/projects');
  }

  return (
    <section id="account-confirm-container">
      <div id="account-confirm-img-container">
        <img src={blueCheckmark} alt="blue checkmark" id="blue-checkmark-img" />
      </div>
      <h1 id="account-confirm-header">Account Verified!</h1>
      <h2 id="account-confirm-description">
        Email confirmation has been completed! <br /> Welcome to Citmit!
      </h2>
      <button id="account-confirm-button" onClick={confirm}>
        To Dashboard
      </button>
      <a href="/" id="account-confirm-to-signin">
        &#60; Back to Sign in{' '}
      </a>
    </section>
  );
}

export default AccountConfirm;
