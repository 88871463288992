import './styles/changePassword.css';
import axios from 'axios';
import React, { useState } from 'react';

function ChangePassword() {
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [email, setEmail] = useState('');

  function change(event) {
    event.preventDefault();
    if (firstPassword === secondPassword) {
      const data = {
        password: firstPassword,
      };

      axios
        .post(
          'https://buildpermit.ai:4000/account-recovery/change-password',
          data
        )
        .then((res) => {
          if (res.status === 200) {
            alert('password changed successful');
          } else {
            console.log('password could not be changed');
          }
        });
    } else {
      alert('passwords do not match');
    }
  }
  return (
    <div id="change-password-container">
      <h1 id="title">Account Recovery</h1>
      <p id="description">Enter a new password</p>
      <form onSubmit={change}>
        <input
          type="email"
          placeholder="Email"
          id="email"
          required
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          id="password"
          required
          onChange={(event) => setFirstPassword(event.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          id="confirm-password"
          required
          onChange={(event) => setSecondPassword(event.target.value)}
        />
        <button id="button">Change password</button>
      </form>
    </div>
  );
}

export default ChangePassword;
