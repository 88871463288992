/*
 * May 10, Raymond: built the skeleton. not much else is needed
 * maybe some better organization but I think its ok
 */
import './styles/expandedProject.css';
import React from 'react';
import Navbar from '../../components/Navbar/navbar.js';
import TopNav from '../../components/Navbar/topnav.js';
import ProjectOverview from '../../components/Project/ExpandedProjects/projectOverviewTile.js';
import ProjectInfo from '../../components/Project/ExpandedProjects/projectInfoTile.js';

function ExpandedProject() {
  return (
    <section id="expanded-project-container">
      <Navbar id="expanded-project-navbar" />
      <TopNav id="expanded-project-topnav" />
      <section id="expanded-project-tiles-section">
        <ProjectOverview id="expanded-project-overview-tile" />
        <ProjectInfo id="expanded-project-info-tile" />
      </section>
    </section>
  );
}

export default ExpandedProject;
