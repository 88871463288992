/*
 * May 7, Andrew: Fixed some of the CSS designs for the forgot password page.
 *
 */
import './styles/forgotPassword.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import lockImage from '../../images/blue-lock.svg';

function Accountrecovery() {
  const [userEmail, setUserEmail] = useState('');

  function recoverPassword(event) {
    event.preventDefault();

    if (!userEmail) {
      document.getElementById('helper-message').innerHTML = 'missing email';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email').style.border = '1px solid red';
      return;
    }

    if (typeof userEmail !== 'string') {
      document.getElementById('helper-message').innerHTML =
        'invalid email input';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email').style.border = '1px solid red';
      return;
    }

    if (!userEmail.includes('@')) {
      document.getElementById('helper-message').innerHTML =
        'invalid email input (missing @)';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email').style.border = '1px solid red';
      return;
    }

    const data = {
      email: userEmail,
    };

    axios
      .post('https://buildpermit.ai:4000/forgot-password', data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById('helper-message').innerHTML = res.data;
          document.getElementById('helper-message').style.color = '#333';
          return true;
        }
      })
      .catch((error) => {
        console.error(error);
        if (!error.response) {
          document.getElementById('helper-message').innerHTML =
            'an error has occured on our servers';
          document.getElementById('helper-message').style.color = '#333';
          return;
        }

        const errorStatus = error.response.status;
        if (errorStatus === 409) {
          document.getElementById('helper-message').innerHTML =
            error.response.data;
          document.getElementById('helper-message').style.color = 'red';
        } else {
          document.getElementById('helper-message').innerHTML =
            'an error has occured on our servers';
          document.getElementById('helper-message').style.color = '#333';
        }
      });
  }

  //<img src={lockImage} alt="blue lock" id="lock-image" />
  return (
    <div id="forgot-password-container">
      <div id="image-container">
        <img src={lockImage} alt="blue lock" id="blue-lock-image" />
      </div>
      <h1 id="forgot-password-header">Forgot your password?</h1>
      <p id="forgot-password-description">
        Please enter the email address associated with your <br /> account and
        we will email you a link to reset your password
      </p>
      <form id="form" onSubmit={recoverPassword}>
        <input
          type="text"
          placeholder="Email"
          id="email"
          onChange={(event) => setUserEmail(event.target.value)}
        />
        <section id="helper-message-container">
          <span id="helper-message"></span>
        </section>
        <button id="button">Send Link</button>
        <a href="/" id="login">
          &#60; Back to Sign In
        </a>
      </form>
    </div>
  );
}

export default Accountrecovery;
