/**
 * Author: Aaron Kann
 * aaron@stevek.com
 */
import React, { useEffect, useState } from 'react';
import './styles/userForm.css';

function UserForm(props) {
    const [tags, setTags] = useState([]);

    // default Things
    const userObj = null;
    const firstName = "First Name";
    const lastName = "Last Name";
    const jobTitle = "Job Title";
    const location = "Location";
    const about = "About";
    const licenseNo = "License No. (if applicable)";

    // if props.user exists, load the user object, otherwise set props.user to null
    if (props.user) {
        userObj = props.user;
    }

    return (
        <div id="userForm">
            <div id="AccountInfo">
                <h id="editAccount">Edit your account information:</h>
                <input id="firstName" type="text" name="First Name" value={firstName}></input>
                <input id="lastName" type="text" name="Last Name" value={lastName}></input>
                <input id="jobTitle" type="text" name="Job Title" value={jobTitle}></input>
                <input id="location" type="text" name="Location" value={location}></input>
                <input id="about" type="text" name="About" value={about}></input>
            </div>
            <div id="ProfessionalInfo">
                <h id="profInfo">Professional Info:</h>
                <input id="license" type="text" name="License No" value={licenseNo}></input>
                <div id="tags">
                    {tags.map((tagObj) => (
                        <tag className="tag ${tagObj.color}">
                            <p>{tagObj.name}</p>
                        </tag>
                    ))}
                    <tag className="tag addTag">
                        <p>Add Tags</p>
                    </tag>
                </div>
            </div>
            <div id="SocialInfo">
                <h id="profiles">Social Profiles</h>
            </div>
            <div id="saveAndCancelButtons">
                <button id="save">Save</button>
                <button id="cancel">Cancel</button>
            </div>
        </div>
    )

}

export default UserForm;