/*
 * May 11, Raymond: added functionality to click on headers
 * and the respective details will show up.
 *
 * TODO: connect to backend to grab the details needed
 * possibly set it up to gather everything from the backend and
 * save it in local storage so that when the user clicks on the
 * header we are not calling the backend everytime
 */
import './styles/projectInfoTile.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ProjectInfo() {
  // info sets the body of the info tile
  // header sets which header is clicked to change color
  const [info, setInfo] = useState();
  const [header, setHeader] = useState(0);

  function setDescription() {
    setInfo(<Description />);
    setHeader(0);
  }

  function setDetails() {
    setInfo(<Details />);
    setHeader(1);
  }

  function setUsers() {
    setInfo(<Users />);
    setHeader(2);
  }

  // this use effect is to set the body of the project info tile on startup
  useEffect(() => {
    setInfo(<Description />);
  }, []);

  //helper function to set colors of project info header links
  function setStyleHeaders(target, unset1, unset2) {
    target.style.borderBottom = '1px solid #6950e8';
    target.style.color = '#6950e8';
    unset1.style.border = 'none';
    unset1.style.color = '#6b7280';
    unset2.style.border = 'none';
    unset2.style.color = '#6b7280';
  }

  // this use effect is to set the style of the headers on project info tile
  useEffect(() => {
    const description = document.getElementById(
      'project-info-description-link'
    );
    const details = document.getElementById('project-info-details-link');
    const users = document.getElementById('project-info-users-link');
    if (header === 0) {
      // description is clicked
      setStyleHeaders(description, details, users);
    } else if (header === 1) {
      // details is clicked
      setStyleHeaders(details, description, users);
    } else if (header === 2) {
      // users is clicked
      setStyleHeaders(users, description, details);
    }
  }, [header]);

  return (
    <section id="project-info-tile-container">
      <div id="project-info-tile-header">
        <a
          href="#description"
          className="project-info-header-links"
          id="project-info-description-link"
          onClick={setDescription}
        >
          Description
        </a>
        <a
          href="#project-details"
          className="project-info-header-links"
          id="project-info-details-link"
          onClick={setDetails}
        >
          Details
        </a>
        <a
          href="#users"
          className="project-info-header-links"
          id="project-info-users-link"
          onClick={setUsers}
        >
          Users
        </a>
      </div>
      <div id="project-info-tile-body">
        <div>{info}</div>
      </div>
    </section>
  );
}

function Description() {
  return (
    <div id="description-component">
      <h3>Description:</h3>
    </div>
  );
}

function Details() {
  return (
    <div id="details-component">
      <h3>Details:</h3>
    </div>
  );
}

function Users() {
  return (
    <div id="users-component">
      <h3>Users:</h3>
    </div>
  );
}

export default ProjectInfo;
