import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import ProjectImage from './projectImage.js';
import mail from '../../images/icons/mail-2.png';

function Project(props) {
  return (
    <div className="project">
      <div className="project-image-container">
        <ProjectImage image={props.image} />
      </div>
      <div className="project-names">
        <p className="project-name">{props.name}</p>
        <h2 className="project-address">{props.address}</h2>
      </div>
      <a className="project-mail-link" href="">
        <img src={mail} alt="Mail Icon" />
      </a>
      <h2 className="project-percentage-text">{props.percentage}%</h2>
      <div className="project-percentage-bar">
        <div
          className="project-percentage-bar-fill"
          style={{ width: `${props.percentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default Project;
