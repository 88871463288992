import './styles/signup.css';
import React from 'react';
import CreateProfile from '../../components/Login/createProfile.js';
import Description from '../../components/Login/description.js';

function Signup() {
  return (
    <div id="signup-container">
      <section id="signup-description-container">
        <Description />
      </section>
      <section id="signup-createprofile-container">
        <CreateProfile />
      </section>
    </div>
  );
}

export default Signup;
