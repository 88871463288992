import axios from 'axios';

export async function readyToPay(e, projectCountId, cost) {
  if (e) e.stopPropagation();
  const products = [
    {
      name: 'test name',
      unitAmount: 50,
      quantity: 5,
    },
  ];

  const data = {
    products: products,
    projectCountId: projectCountId,
    cost: cost,
  };

  console.log('this is data that is gonna be sent for payment', data);

  axios
    .post('https://buildpermit.ai:4000/payment', data, {
      withCredentials: true,
    })
    .then((res) => {
      console.log('this is res from payment', res);
      window.open(res.data.url);
    })
    .catch((error) => {
      console.log('an error has occured during payment', error);
    });
}
