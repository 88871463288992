import './styles/resetPassword.css';
import React, { useState } from 'react';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import blueLock from '../../images/blue-lock.svg';
import { useSearchParams } from 'react-router-dom';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [searchParams] = useSearchParams();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const token = searchParams.get('email_token');

  function submitPasswordReset(e) {
    e.preventDefault();
    const helperMessage = document.getElementById('helper-message');

    if (!email) {
      document.getElementById('reset-email').style.border = '1px solid red';
      helperMessage.innerHTML = 'missing email';
      return;
    }

    if (!email.includes('@')) {
      document.getElementById('reset-email').style.border = '1px solid red';
      helperMessage.innerHTML = 'invalid email input (missing @)';
      return;
    }

    if (typeof email !== 'string') {
      document.getElementById('reset-email').style.border = '1px solid red';
      helperMessage.innerHTML = 'invalid email input';
      return;
    }

    if (!password) {
      document.getElementById('reset-password').style.border = '1px solid red';
      helperMessage.innerHTML = 'no password set';
      return;
    }

    if (!confirmPassword) {
      document.getElementById('reset-confirm').style.border = '1px solid red';
      helperMessage.innerHTML = 'no password set';
      return;
    }

    if (password !== confirmPassword) {
      document.getElementById('reset-confirm').style.border = '1px solid red';
      document.getElementById('reset-password').style.border = '1px solid red';
      helperMessage.innerHTML = 'passwords do not match';
      return;
    }

    const data = {
      email: email,
      newPassword: password,
      confirmedPassword: confirmPassword,
    };

    helperMessage.innerHtml = 'resetting password...';
    helperMessage.style.color = '#333';

    axios
      .patch(`https://buildpermit.ai:4000/reset-password/${token}`, data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          helperMessage.innerHTML = res.status.data;
          helperMessage.style.color = '#333';
        }
      })
      .catch((error) => {
        console.error(error);
        if (!error.response) {
          helperMessage.innerHTML = 'an error has occured on our servers';
          helperMessage.style.color = '#333';
          return;
        }
        const errorStatus = error.response.status;
        if (errorStatus === 409) {
          helperMessage.innerHTML = error.response.data;
          return;
        } else if (errorStatus === 500) {
          helperMessage.innerHTML = error.response.data;
          return;
        } else {
          helperMessage.innerHTML = 'an error has occured on our servers';
          helperMessage.style.color = '#333';
          return;
        }
      });
  }

  return (
    <section id="password-reset-container">
      <div id="password-reset-img-container">
        <img src={blueLock} alt="blue lock" id="password-reset-img" />
      </div>
      <h1 id="password-reset-header">Reset password</h1>
      <h2 id="password-reset-description">
        please enter your account email and a new password
      </h2>
      <form onSubmit={submitPasswordReset} id="password-reset-form">
        <input
          type="text"
          placeholder="Email"
          className="password-reset-input"
          id="reset-email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <div id="password-container">
          <input
            type={visiblePassword ? 'text' : 'password'}
            placeholder="Password"
            className="password-reset-input"
            id="reset-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          {visiblePassword ? (
            <EyeOutlined
              id="visible-icon"
              onClick={() => setVisiblePassword(!visiblePassword)}
            />
          ) : (
            <EyeInvisibleOutlined
              id="visible-icon"
              onClick={() => setVisiblePassword(!visiblePassword)}
            />
          )}
        </div>
        <div id="password-container">
          <input
            type={visibleConfirm ? 'text' : 'password'}
            placeholder="Confirm Password"
            className="password-reset-input"
            id="reset-confirm"
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          {visibleConfirm ? (
            <EyeOutlined
              id="visible-icon"
              onClick={() => setVisibleConfirm(!visibleConfirm)}
            />
          ) : (
            <EyeInvisibleOutlined
              id="visible-icon"
              onClick={() => setVisibleConfirm(!visibleConfirm)}
            />
          )}
        </div>
        <section id="helper-message-container">
          <span id="helper-message"></span>
        </section>
        <button id="password-reset-button">Confirm Reset</button>
      </form>
      <a href="/" id="account-confirm-to-signin">
        &#60; Back to Sign in{' '}
      </a>
    </section>
  );
}

export default PasswordReset;
