import './styles/tickets.css';
import React, { useMemo, useState } from 'react';
import axios from 'axios';
import Collapsible from 'react-collapsible';
import downArrow from '../../images/down_arrow.svg';
import Loading from '../Loading/loading.js';

function Tickets() {
  const [supportTickets, setSupportTickets] = useState(null);
  const [load, setLoad] = useState(true);
  const [showTickets, setShowTickets] = useState();

  useMemo(() => {
    if (!supportTickets) {
      axios
        .get(
          `https://buildpermit.ai:${process.env.REACT_APP_PORT}/support/tickets`,
          { withCredentials: true }
        )
        .then((res) => {
          if (res) {
            setSupportTickets(res.data.first_name ? res.data : null);
            setShowTickets(
              supportTickets
                ? supportTickets.map((data, key) => {
                    return <EachTicket data={data} key={key} />;
                  })
                : null
            );
          } else {
            console.log('could not get support tickets');
          }
        })
        .catch((error) => {
          console.log('could not get support tickets');
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  }, [supportTickets]);

  function EachTicket(props) {
    function Triggers(props) {
      return (
        <div className="triggers-down-container">
          {props.subject}
          <img
            src={downArrow}
            alt="down arrow"
            className="triggers-down-arrow"
          />
        </div>
      );
    }
    return (
      <div id="support-each-ticket-container">
        <Collapsible
          trigger={<Triggers subject={props.data.subject} />}
          triggerWhenOpen={<Triggers subject={props.data.subject} />}
        >
          <button
            className="btn btn-block"
            onClick={() => {
              this.toggle.bind('demo');
            }}
          >
            <div className="each-ticket-contents-container">
              <p className="each-ticket-name">
                Name: {props.data.first_name + ' ' + props.data.last_name}
              </p>
              <p className="each-ticket-email">
                Email: {props.data.user_email}
              </p>
              <p className="each-ticket-product">
                Product: {props.data.product}
              </p>
              <p className="each-ticket-message">{props.data.message}</p>
            </div>
          </button>
        </Collapsible>
      </div>
    );
  }

  return (
    <div id="tickets-container">
      {load ? (
        <Loading subject={'Loading tickets...'} />
      ) : supportTickets ? (
        <div id="tickets-inner-container">{showTickets}</div>
      ) : (
        <div id="no-tickets">
          <h4 id="no-tickets-header">No tickets have been created</h4>
        </div>
      )}
    </div>
  );
}

export default Tickets;
