import './styles/faq.css';
import React, { useMemo, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { SearchOutlined } from '@ant-design/icons';

import { faq_list } from './faq_list.js';
import downArrow from '../../images/down_arrow.svg';

function FAQ() {
  let [showFaqs, setShowFaqs] = useState();
  let filterdFaqs = [];
  function EachFaq(props) {
    function Triggers(props) {
      return (
        <div className="triggers-down-container">
          {props.question}
          <img
            src={downArrow}
            alt="down arrow"
            className="triggers-down-arrow"
          />
        </div>
      );
    }

    return (
      <div className="each-faq-container">
        <Collapsible
          trigger={<Triggers question={props.data.question} />}
          triggerWhenOpen={<Triggers question={props.data.question} />}
        >
          <button
            className="btn btn-block"
            onClick={() => {
              this.toggle.bind('demo');
            }}
          >
            <div className="each-faq-answer-container">{props.data.answer}</div>
          </button>
        </Collapsible>
      </div>
    );
  }

  useMemo(() => {
    setShowFaqs(
      faq_list.map((data, key) => {
        return <EachFaq data={data} key={key} />;
      })
    );
  }, []);

  function searchFaqs(e) {
    e.preventDefault();
    filterdFaqs = [];
    const userSearch = e.target.value.toLowerCase();

    for (let i in faq_list) {
      const question = faq_list[i].question.toLowerCase();
      if (question.includes(userSearch)) {
        let tempObj = {
          question: faq_list[i].question,
          answer: faq_list[i].answer,
        };
        filterdFaqs.push(tempObj);
      }
    }
    console.log(filterdFaqs);
    setShowFaqs(
      filterdFaqs.map((data, key) => {
        return <EachFaq data={data} key={key} />;
      })
    );
  }

  return (
    <div id="faq-container">
      <h3 id="faq-header">Fequently Asked Questions</h3>
      <div id="search-container">
        <SearchOutlined id="search-glass" />
        <input
          type="text"
          placeholder="Search"
          id="search-faq"
          onChange={searchFaqs}
        />
      </div>
      <section>{showFaqs}</section>
    </div>
  );
}

export default FAQ;
