import './styles/contactUs.css';
import React, { useState } from 'react';
import axios from 'axios';

function ContactUs() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  function submitEmail(e) {
    e.preventDefault();
    const helper = document.getElementById('helper-message');

    const data = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };

    helper.innerHTML = 'sending email...';
    axios
      .post(
        `https://buildpermit.ai:${process.env.REACT_APP_PORT}/support/emails`,
        data,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log('successfull email:', res);
        helper.innerHTML = res.data;
      })
      .catch((error) => {
        console.log('error support email', error);
        const helperMessage = document.getElementById('helper-message');
        if (!error.response) {
          helperMessage.innerHTML = 'an error occured with our servers';
          return;
        }
        if (error.response.status === 400) {
          helperMessage.innerHTML = error.response.data;
          return;
        } else {
          helperMessage.innerHTML = 'an error occured with our servers';
          return;
        }
      });
  }

  return (
    <div id="contact-us-container">
      <h3 id="contact-us-header">Send us an Email</h3>
      <form id="form-contact-us" onSubmit={submitEmail}>
        <input
          type="text"
          placeholder="Name*"
          id="name-contact-us"
          className="contact-us-inputs"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <input
          type="email"
          placeholder="Email*"
          id="email-contact-us"
          className="contact-us-inputs"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Subject*"
          id="subject-contact-us"
          className="contact-us-inputs"
          onChange={(event) => {
            setSubject(event.target.value);
          }}
        />
        <textarea
          id="text-area-contact-us"
          className="contact-us-inputs"
          placeholder="Message"
          rows="6"
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
        <section id="helper-message-container">
          <span id="helper-message"></span>
        </section>
        <button id="submit-contact-us">Submit</button>
      </form>
    </div>
  );
}

export default ContactUs;
