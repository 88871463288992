/**
 * Andrew Park 6/22/2024
 * This component is used to display the PDF viewer pop-up.
 */

import { React } from "react";
import "./styles/pdfViewerPopUp.css";
import pdf from "./dummy.pdf";

function PDFViewerPopUp(props) {
  return props.show ? (
    <div className="pdf-viewer-popup">
      <div className="pdf-viewer-popup-inner">
        <embed src={pdf} type="application/pdf" />
        <button className="pdf-viewer-close-button" onClick={props.onClose}>
          Close
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}

export default PDFViewerPopUp;
