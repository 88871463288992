import '../../pages/Project/styles/createProject.css';
import './styles/finishedProject.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Loading from '../Loading/loading.js';
import { readyToPay } from '../utils/payment';

function FinishedPopup({ cost, projectId }) {
  const [load, setLoad] = useState(false);
  const [paid, setPaid] = useState(false);

  const navigate = useNavigate();

  function PaidFor(props) {
    return (
      <div id="payment-ready-container">
        <span id="payment-ready-subject">payment is ready</span>
      </div>
    );
  }

  function ReadyToPay() {
    function payment(e) {
      setLoad(true);
      console.log('this is project id in payment:', projectId);
      readyToPay(e, projectId, cost);
    }

    function draft() {
      document.getElementById('display-finished-project-popup').style.display =
        'none';
      navigate('/projects');
    }

    return (
      <div id="ready-to-pay-container">
        <h4 id="ready-to-pay-header">Project is created! </h4>
        <p>click on checkout to complete or save as draft</p>
        <button id="create-project-button" onClick={payment}>
          Checkout
        </button>
        <button id="save-draft-project-button" onClick={draft}>
          Save Draft
        </button>
      </div>
    );
  }

  return (
    <div id="finished-project-popup-container">
      <div id="inner-finished-container">
        {load ? (
          <Loading subject={'loading payment...'} />
        ) : paid ? (
          <PaidFor />
        ) : (
          <ReadyToPay />
        )}
      </div>
    </div>
  );
}

export default FinishedPopup;
