import './styles/draftPopup.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import loading from '../../images/Loading_infinite.gif';

function DraftPopup({ draftLoad, draftDescription }) {
  const navigate = useNavigate();
  function DraftLoading() {
    return (
      <div id="draft-loading-container">
        <img src={loading} alt="loading gif" id="draft-loading-spin-gif" />
        <p id="draft-loading-description">{draftDescription}</p>
      </div>
    );
  }

  function DraftReady() {
    function closeDraft(e) {
      e.preventDefault();
      navigate('/projects');
    }
    return (
      <div id="draft-ready-container">
        <h4 id="draft-ready-header">Project saved as draft!</h4>
        <p id="draft-ready-description">Check Draft tab to finish project</p>
        <button id="draft-ready-button" onClick={closeDraft}>
          Close
        </button>
      </div>
    );
  }

  return (
    <div id="draft-popup-container">
      <div id="inner-draft-popup-container">
        {draftLoad ? <DraftLoading /> : <DraftReady />}
      </div>
    </div>
  );
}

export default DraftPopup;
