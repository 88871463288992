/*
 * May 14, Raymond: working on connecting to backend. need to send cookie
 * as well as the project that was clicked, and I expect just the images
 * im thinking i create a third file and both components will call on that file
 * to call the backend so that we are hitting the endpoint just once and grabbing
 * what we need
 *
 * TODO (frontend is done, just need to connect backend)
 * grab all images from the database for the project and display them
 * in an array format
 *
 * TODO (will create a component that will set the status)
 * add the status of the project on top of the image
 *
 * TODO (frontend should be done, just need to grab from backend)
 * add the address and title of the project to the right along with needed
 * buttons, edit prject details and view report
 *
 * TODO
 * give functionality to buttons to do as intended
 *
 * TODO
 * add a edit function to the edit project details button
 */
import './styles/projectOverviewTile.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import image from '../../../images/image.png';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// just temporary imports, delete later
import apple from '../../../images/Apple.png';
import google from '../../../images/Google.png';
import meta from '../../../images/Meta.png';
import firm from '../../../images/Firm Icon 1.png';
import building from '../../../images/1024px-Government_Building_icon 1.png';

let projectImages = [
  { image: { apple } },
  { image: { google } },
  { image: { meta } },
  { image: { firm } },
  { image: { building } },
  { image: { apple } },
  { image: { google } },
  { image: { meta } },
  { image: { firm } },
  { image: { building } },
];

//dummyImages = [];

function ProjectOverview() {
  const [render, setRender] = useState();
  const [renderObj, setRenderObj] = useState();
  const { state } = useLocation();
  let renderState = useRef();
  let imageMapping = [];

  console.log('this is state', state);
  const { projectId } = state;
  console.log('this is projectid', projectId);

  function thisRender(number) {
    const clickedImg = document.getElementById(number).src;
    setRender(<EnlargedRender img={clickedImg} num={number} />);
  }

  // helper function to render images in array
  // just grabbing the first object from the object list we are given, may or may
  // not need to change that
  function ProjectRender(props) {
    return (
      <div className="project-overview-render-container">
        <a href="#render" className="project-overview-render-atag">
          <img
            src={props.image[Object.keys(props.image)[0]]}
            alt="project render"
            className="project-overview-render-image"
            id={props.number}
            onClick={(event) => thisRender(event.target.id)}
          />
        </a>
      </div>
    );
  }

  // using dummy images as a substitute at the moment, need to change that and
  // get images from the backend and display those
  // Also need to get the status of the project plancheck
  if (projectImages.length !== 0) {
    imageMapping = projectImages.map((data, key) => (
      <ProjectRender image={data.image} number={key} />
    ));
  } else {
    projectImages = [{ image: { image } }];
    imageMapping = projectImages.map((data, key) => (
      <ProjectRender image={data.image} number={key} />
    ));
  }

  useEffect(() => {
    const cookies = new Cookies();
    // get users info from backend based on cookie
    async function getUserData() {
      const userCookie = cookies.get('token');
      const clickedProject = localStorage.getItem('clickedProject');
      try {
        axios
          .get(`https://buildpermit.ai:4000/get-renders/${clickedProject}`, {
            headers: {
              Authorization: `Bearer ${userCookie}`,
              Project: `id ${clickedProject}`,
            },
          })
          .then((res) => {
            // check all status codes for response
            if (res.status === 200) {
              setRenderObj(...res.body.renderArray);
              renderState = renderObj.map((data, key) => {
                <ProjectRender image={data} number={key} />;
              });
            }
          })
          .catch((error) => {});
      } catch (error) {
        console.log('unable to grab user data from backend');
        /*
        alert(
          'an error has occured and the software has logged you out, sending you back to login'
        );
        return redirect('/');
        */
      }
    }

    getUserData();
    const clickedImg = document.getElementById('0').src;
    setRender(<EnlargedRender img={clickedImg} num={0} />);
  }, []);

  return (
    <div id="project-overview-container">
      <div id="inner-project-overview-container">
        <section id="overview-side-imgs">
          {imageMapping ? imageMapping : { image }}
        </section>
        <section id="overview-clicked-img">
          <div id="overview-clicked-img-container">{render}</div>
        </section>
        <section id="project-overview-info-section">
          <div id="project-overview-info-inner-container">
            <div id="project-overview-status-container">
              <div id="project-overview-status-div">Status goes here</div>
            </div>
            <div id="project-overview-name-and-address-container">
              <h3 id="project-overview-address">Address goes here</h3>
              <h2 id="project-overview-name">Name goes here</h2>
            </div>
            <div id="project-overview-price-container">
              <p id="project-overview-price">$420</p>
            </div>
            <div id="project-overview-buttons-container">
              <button id="project-overview-edit-button">
                Edit Project Details
              </button>
              <button id="project-overview-view-button">View Report</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

//helper function to get component for enlarged render
function EnlargedRender(props) {
  const renderId = `enlarged-render-of-${props.num}`;
  return (
    <div id="enlarged-render-container">
      <div id="inner-enlarged-render-container">
        <img
          src={props.img}
          alt="Enlarged render"
          className="enlarged-render"
          id={renderId}
        />
      </div>
    </div>
  );
}

export default ProjectOverview;
