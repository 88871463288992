import './styles/success.css';
import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import redCross from '../../images/red_crossout.png';

function Failure() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    function paymentSuccess() {
      const sessionId = searchParams.get('session_id');
      const projectId = searchParams.get('project_id');

      axios
        .get(
          `https://buildpermit.ai:4000/payment/failure?session_id=${sessionId}&project_id=${projectId}`,
          { withCredentials: true }
        )
        .then((res) => {
          console.log('this is res payment failure', res);
        })
        .catch((error) => {
          console.log('an error occured on payment success');
          console.error(error);
        });
    }

    paymentSuccess();
  }, [searchParams]);

  function goto(e) {
    e.preventDefault();
    navigate('/projects');
  }

  return (
    <div id="success-page-container">
      <img src={redCross} alt="blue checkmark" id="failure-red-crossout" />
      <h1 id="payment-success-header">Payment Failed</h1>
      <p id="payment-success-description">
        Your payment could not be completed
      </p>
      <button
        id="payment-success-button"
        onClick={goto}
        style={{ backgroundColor: 'red' }}
      >
        Go Back
      </button>
    </div>
  );
}

export default Failure;
