import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './styles/preview.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Preview({ selectedPdf }) {
  const containerRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [pageWidth, setPageWidth] = useState(null);
  const [scale, setScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
    }
  }, []);

  const onPageLoadSuccess = () => {
    //const pageWidth = page.getViewport({ scale: 1 }).width;
    const newScale = 1;
    setPageWidth(newScale);
    setScale(newScale);
  };

  const renderPages = () => {
    const pages = [];
    if (containerWidth && numPages) {
      for (let i = 1; i <= numPages; i++) {
        pages.push(
          <div key={i} className="individualPageContainer">
            <Page
              pageNumber={i}
              onLoadSuccess={onPageLoadSuccess}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={containerWidth}
              scale={scale}
              className="page-pdf-page"
            />
          </div>
        );
      }
    }
    return pages;
  };

  return (
    <div className="pdf-container" ref={containerRef}>
      {selectedPdf && ( // Only render Document if selectedPdf is truthy
        <Document
          file={{ url: selectedPdf }}
          onLoadSuccess={onDocumentLoadSuccess}
          className="individual-pdf"
        >
          {renderPages()}
        </Document>
      )}
    </div>
  );
}

export default Preview;
